import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Helmet } from 'react-helmet';
import { connect } from "react-redux";
import $ from 'jquery';

import { IMAGES, ROUTE_CONSTANTS, SERVER_URL, SIDEBAR_CONTENT_TYPES, TEXT_CONST } from "../../../shared";
import "./styles.scss";
import {
  logoutRequest,
  uploadDocument,
  sendDocumentsToVerify,
  getCasinosListForVerification,
  resetSidePanel,
  updateVerifyDocumentDetails,
  updateHistoryList,
} from "../../../redux/actions";
import { Left_SideMenu, LoginModal, SignUpModal } from "../../atoms";
import MyProfileModal from "../MyProfileModal";
import MenuList from "../MenuList";
import { Uttag, VerificationFirstStep } from "../../cells";
import VerificationHistory from "../../../views/1.0/secured/VerificationHistory";
import { defaultConfig } from "../../../config/default";

let sec = "";
const Header = ({
  title = "",
  link = "",
  userToken,
  showApprovePopup = false,
  logoutRequest,
  isHomepage = true,
  userData: { name = "" } = {},
  uploadDocument = () => { },
  sendDocumentsToVerify = () => { },
  getCasinosListForVerification = () => { },
  verificationDocDetails,
  resetSidePanel = () => { },
  isSidePanelClassname,
  updateVerifyDocumentDetails = () => { },
  updateHistoryList = () => { },
  verificationDynamicContent
}) => {
  const [showMenu, toggleMenu] = useState(false);
  const [menuClass, updateMenuClass] = useState("");
  const [showLoginModal, toggleLoginModal] = useState(false);
  const [showSignupModal, toggleSignupModal] = useState(false);
  const [showLoginIcon, setShowLoginIcon] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const [profileModal, showProfileModal] = useState(false);
  const [verfify_secondScreen, setVerifySecondScreen] = useState(false);
  const [fileFirst, set_fileFirst] = useState();
  const [fileSecond, set_fileSecond] = useState();
  const [isClose, set_isClose] = useState(false);
  const [history, setHistory] = useState(useHistory());
  const [verification_data, set_verificationData] = useState({
    casino_name: "",
    refunded_amount: verificationDynamicContent.compensationFieldPlaceholder || TEXT_CONST.HEADER.REFUNDED_AMOUNT,
    depositMoneyImage_Id: "",
    lostMoneyImage_Id: "",
  });
  const [isSucess, set_isSucess] = useState(false);
  const [firstFile, setFirstFile] = useState("");
  const [secondFile, setsecondFile] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [casinoList, set_casinoList] = useState([]);
  const [isUpdateDoc, setIsUpdateDoc] = useState(false);
  const [docIdToBeUpdated, setDocIdToBeUpdated] = useState("");
  const [isLostImageChange, setIsLostImageChange] = useState(false);
  const [isDepositImageChange, setIsDepositImageChange] = useState(false);
  const [defaultCasino, setDefaultCasino] = useState("");

  let menuRef = useRef(false);

  useEffect(() => {
    if (userToken) {
      getCasinosListForVerification({
        success: (data) => {
          set_casinoList(data);
        },
        fail: (message) => { },
      });
    }
  }, [userToken]);

  useEffect(() => {
    document.addEventListener('load', trackLogoScrolling);
    document.addEventListener('load', handleHeaderVisibility);
    document.addEventListener("scroll", trackScrolling);
    document.addEventListener("scroll", trackLogoScrolling);
    trackScrolling();
    trackLogoScrolling();
    menuRef.current = showMenu;

    return () => {
      document.removeEventListener('load', trackLogoScrolling);
      document.removeEventListener('load', handleHeaderVisibility);
      document.removeEventListener("scroll", trackScrolling);
      document.removeEventListener("scroll", trackLogoScrolling);
    };
  }, [showMenu, menuClass]);

  useEffect(() => {
    /* Open verification sidepanel and set state value whenever there's some value in redux state and specify variable for checking it's a update*/
    isSidePanelClassname &&
      openSidebarAndupdateCasino(isSidePanelClassname, verificationDocDetails);
    setIsUpdateDoc(true);
  }, [verificationDocDetails, isSidePanelClassname]);

  useEffect(() => {
    /* If there is no sidepanel value from redux update state of updation doc */
    !isSidePanelClassname && setIsUpdateDoc(false);
  }, [isSidePanelClassname]);

  useEffect(() => {
    window.addEventListener("click", () => {
      if (menuRef.current) {
        // toggleMenu(false);
        // setVerifySecondScreen(false);
        updateMenuClass("");
      }
    }, true);
    return () => window.removeEventListener("click", () => { });
  }, []);

  const updateClass = (name = "") => {
    updateMenuClass(name == menuClass ? "" : name);
    toggleMenu(false);
    set_verificationData({
      casino_name: "",
      refunded_amount: TEXT_CONST.HEADER.REFUNDED_AMOUNT,
      depositMoneyImage_Id: "",
      lostMoneyImage_Id: "",
    });
    set_fileSecond("");
    set_fileFirst("");
    set_isSucess(false);
    setDefaultCasino("");
    setIsLostImageChange(false);
    setIsDepositImageChange(false);
    const body = document.querySelector("body");
    // body.classList.toggle("scroll-Bodynone");
    setDocIdToBeUpdated("");
  };

  const openSidebarAndupdateCasino = (name = "", data) => {
    updateMenuClass(name);
    setDocIdToBeUpdated(data.docId);
    toggleMenu(false);
    // const fileOne = {
    //   name: data.depositImageName,
    //   src: SERVER_URL + `${data.depositImageSrc}`,
    //   id: data.depositImageId,
    // };
    // const fileTwo = {
    //   name: data.depositImageName,
    //   src: SERVER_URL + `${data.depositImageSrc}`,
    //   id: data.lostImageId,
    // };
    set_verificationData({
      ...verification_data,
      casino_name: data.casinoId,
      refunded_amount: data.amount,
      depositMoneyImage_Id: data.depositImageId,
      lostMoneyImage_Id: data.lostImageId,
    });

    setDefaultCasino({ value: data.casinoId, label: data.casinoName });
    // setFirstFile(data.depositImageId);
    // setsecondFile(data.lostImageId);
    // setIsLostImageChange(true);
    // setIsDepositImageChange(true);
    // set_fileSecond(fileOne);
    // set_fileFirst(fileTwo);
    // const body = document.querySelector("body");
    // body.classList.toggle("scroll-Bodynone");

    /* for persisted casino to be updated info - removal */
    resetSidePanel("");
  };

  useEffect(() => {
    if (verification_data.depositMoneyImage_Id) {
      if (
        !isDepositImageChange &&
        typeof sec === "object" &&
        sec.lastModified
      ) {
        uploaddocument(sec, set_lostImage);
      }
    }
  }, [verification_data.depositMoneyImage_Id]);

  useEffect(() => {
    if (verification_data.depositMoneyImage_Id) {
      if (!isDepositImageChange && !sec.lastModified) {
        updateHistoryDoc();
      }
    }
  }, [verification_data.depositMoneyImage_Id]);

  useEffect(() => {
    if (!!verification_data.lostMoneyImage_Id && !isLostImageChange) {
      let payload = {
        depositMoneyImage: verification_data.depositMoneyImage_Id,
        lostMoneyImage: verification_data.lostMoneyImage_Id,
        casino: verification_data.casino_name,
        compensation: verification_data.refunded_amount,
      };

      if (docIdToBeUpdated) {
        updateHistoryDoc();
        return;
      }
      sendDocumentsToVerify({
        payload,
        success: (data) => {
          set_isSucess(true);
          set_verificationData({
            casino_name: "",
            refunded_amount: "",
            depositMoneyImage_Id: "",
            lostMoneyImage_Id: "",
          });

          updateHistoryList(true);
          enqueueSnackbar(
            TEXT_CONST.HEADER.DOC_SUBMITTED,
            {
              variant: "success",
            }
          );
        },
        fail: (message) => {
          enqueueSnackbar(message, { variant: "error" });
        },
      });
    }
  }, [verification_data.lostMoneyImage_Id]);

  let isBottom = (el) => {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  };

  let trackScrolling = async () => {
    const wrappedElement = document.getElementById("home-banner-div");
    if (!wrappedElement) {
      return setShowLoginIcon(true);
    }
    if (isBottom(wrappedElement)) {
      return setShowLoginIcon(true);
    }
    return setShowLoginIcon(false);
  };

  let handleHeaderVisibility = () => {
    if (!userToken) {
      return $("#header-id").hide();
    }
  }

  let trackLogoScrolling = async () => {
    const wrappedElement = document.getElementById("logo-main-id");
    const headerElement = document.getElementById("header-id");
    if (!wrappedElement) {
      headerElement.style.borderBottom = "1px solid #4c864a";
      setShowLogo(true);
      $("#header-id").show();
      return;
    }
    if (wrappedElement.getBoundingClientRect().bottom <= headerElement.getBoundingClientRect().bottom) {
      headerElement.style.borderBottom = "1px solid #4c864a";
      setShowLogo(true);
      $("#header-id").show();
      return;
    }
    headerElement.style.borderBottom = "transparent";
    setShowLogo(false);
    if (!userToken) {
      $("#header-id").hide();
    }
    return;
  };

  const updateHistoryDoc = () => {
    let payload = {
      depositMoneyImage: verification_data.depositMoneyImage_Id,
      lostMoneyImage: verification_data.lostMoneyImage_Id,
      casino: verification_data.casino_name,
      compensation: verification_data.refunded_amount,
    };
    if (docIdToBeUpdated) {
      payload.verifyDocsId = docIdToBeUpdated;
      setDocIdToBeUpdated("");
      updateVerifyDocumentDetails({
        payload,
        success: (data) => {
          set_isSucess(true);
          set_verificationData({
            casino_name: "",
            refunded_amount: "",
            depositMoneyImage_Id: "",
            lostMoneyImage_Id: "",
          });
          set_fileFirst("");
          set_fileSecond("");
          setDocIdToBeUpdated("");
          enqueueSnackbar(TEXT_CONST.HEADER.DOC_SUBMITTED_FOR_UPDATION, {
            variant: "success",
          });
          updateHistoryList(true);
        },
        fail: (message) => {
          set_isSucess(true);
          set_verificationData({
            casino_name: "",
            refunded_amount: "",
            depositMoneyImage_Id: "",
            lostMoneyImage_Id: "",
          });
          setDocIdToBeUpdated("");
          set_fileFirst("");
          set_fileSecond("");
          enqueueSnackbar(message, { variant: "error" });
        },
      });
    }
  };

  const set_lostImage = (id) => {
    set_verificationData({ ...verification_data, lostMoneyImage_Id: id });
    setsecondFile(id);
  };

  const set_depositId = (id) => {
    set_verificationData({ ...verification_data, depositMoneyImage_Id: id });
    setFirstFile(id);
  };

  const sendDocumentsForVerification = (
    firstFile,
    secondFile,
    casinoName,
    compensationAmount,
    docIdToBeUpdated
  ) => {
    sec = secondFile;
    if (typeof firstFile === "object" && firstFile.lastModified) {
      uploaddocument(firstFile, set_depositId);
      setIsDepositImageChange(false);
      setIsLostImageChange(false);
    } else if (typeof sec === "object" && sec.lastModified) {
      uploaddocument(sec, set_lostImage);
      setIsDepositImageChange(false);
      setIsLostImageChange(false);
    } else {
      updateHistoryDoc();
    }
  };

  const uploaddocument = (file, set_id) => {
    let payload = {
      file,
    };
    uploadDocument({
      payload,
      success: (data) => {
        set_id(data._id);
      },
      fail: (message) => {
        enqueueSnackbar(TEXT_CONST.SOMETHING_WENT_WRONG, { variant: "error" });
      },
    });
  };

  return (
    <div className={menuClass}>
      {title ? (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      ) : null}
      <header id="header-id" className={`${userToken ? "login" : "no-login"} ${title ? "in-review-details" : ""}`}>
        <div className="container-fluid ">
          <nav className="navbar navbar-expand-lg  p-0 main-Menuse">
            <a onClick={() => updateClass("close_nav")} className=" tog_nav">
              <span className="cross"></span>
            </a>
            {title ? (
              <Link className={`logo-title tog_title ${userToken ? "login" : "logout"}`} to={userToken ? link : ROUTE_CONSTANTS.ROOT}>
                <span>{title}</span>
              </Link>
            ) : null}
            {
              !title && showLogo ?
                <div className="logo_title">
                  <img
                    src={
                      (defaultConfig.LOCATION).toLowerCase() === 'fi'
                        ?
                        IMAGES.HEADER_RISKIVAPAA_LOGO
                        :
                        IMAGES.LOGO}
                    alt="logo"
                    width="200"
                    onClick={() => history.push(ROUTE_CONSTANTS.ROOT)}
                    style={{ cursor: 'pointer' }}
                  />
                  <span>{title}</span>
                </div>
                : null
            }

            {!!showLoginModal && (
              <LoginModal
                onClose={() => history.goBack() || history.push(ROUTE_CONSTANTS.ROOT)}
                onOpenSignup={() => {
                  history.push(ROUTE_CONSTANTS.REGISTER);
                }}
                show={showLoginModal}
              />
            )}
            {!!showSignupModal && (
              <SignUpModal
                onClose={() => history.goBack() || history.push(ROUTE_CONSTANTS.ROOT)}
                onOpenLogin={() => {
                  history.push(ROUTE_CONSTANTS.LOGIN)
                }}
                show={showSignupModal}
              />
            )}
            {!!userToken && (
              <MenuList
                name={name}
                updateClass={updateClass}
                toggleMenu={toggleMenu}
                showMenu={showMenu}
                showProfileModal={showProfileModal}
                logoutRequest={logoutRequest}
                history={history}
                showApprovePopup={showApprovePopup}
              />
            )}
            {!userToken && !!showLoginIcon && (
              <div className="mobile_login_buttons text-right">
                <a className="login_bnt" onClick={() => history.push(ROUTE_CONSTANTS.LOGIN)}>
                  <i class="fa fa-sign-in fa-2x" aria-hidden="true"></i>
                </a>
                <a className="register_btn"
                  onClick={() => history.push(ROUTE_CONSTANTS.REGISTER)}
                >
                  <i class="fa fa-user fa-2x" aria-hidden="true"></i>
                </a>
              </div>
            )}
          </nav>
        </div>
        {/* left side menu */}
        {/* my profile modal  */}
        {profileModal && (
          <MyProfileModal
            onClose={(e) => {
              // e.preventDefault();
              showProfileModal(false);
            }}
            profileModal={profileModal}
          />
        )}
        <Left_SideMenu />
        {/* verification  */}
        <div className="right-Sidebarse">
          <div className="off-nav">
            <a onClick={() => updateClass("")}>
              <span className="cross"></span>
            </a>
          </div>
          <VerificationFirstStep
            casinoList={casinoList}
            setVerifySecondScreen={setVerifySecondScreen}
            set_verificationData={set_verificationData}
            setDefaultCasino={setDefaultCasino}
            verification_data={verification_data}
            sendDocumentsForVerification={sendDocumentsForVerification}
            isSucess={isSucess}
            updateClass={updateClass}
            set_fileFirst={set_fileFirst}
            set_fileSecond={set_fileSecond}
            fileFirst={fileFirst}
            fileSecond={fileSecond}
            isClose={isClose}
            docIdToBeUpdated={docIdToBeUpdated}
            updateHistoryDoc={updateHistoryDoc}
            defaultCasino={defaultCasino}
          />

          {/* Money Withdrawal From wallet */}
          <Uttag close={() => updateClass("")} showApprovePopup={showApprovePopup} />

          {/* uttag second step, instructions for make payment  */}
          <div className="left-Menuse right-Menuse4 utan-Stp3">
            {/* History List */}
            <VerificationHistory />
          </div>
          {/* <div className="left-Menuse right-Menuse4 utan-Stp4">
            <div className="step-Wrapse">
              <div className="step-Topse">
                <p>VERIFIERA OCH FÅ PENGAR TILLBAKA</p>
                <div className="website-logo-container" style={{ width: "200px" }}>
                  <img src={IMAGES.LOGO} alt="logo" />
                </div>
              </div>
              <div className="step-Secondse rht-Second2">
                <h3>
                  Tack för att du skickade
                  <br />
                  in dokumenten för verifiering!
                </h3>
                <p>
                  När vi är klar med verifieringen och den har blivit godkänd får du en notifikation här på sidan och
                  din kontobalans på Riskfritt.se fylls på automatiskt.
                </p>
              </div>
              <div className="step-Secondse rht-Second2">
                <h3>
                  Verifiera med BankID för <br />
                  att skicka in dokumenten
                </h3>
                <p>
                  För att vi ska kunna betala tillbaka summan du förlorade på din första insättning på ett valfritt
                  casino från vår topplista så lär vi på Riskfritt.se först bekräfta att du är en.
                </p>
              </div>
              <div className="binny-Mosco">
                <img src="assets/images/ban-img.png" alt="..." />
              </div>
              <div className="input-Grupse2">
                {" "}
                <a href="" className="primary-Gbtn full-Width dark-Green">
                  STÄNG{" "}
                </a>{" "}
              </div>
            </div>
          </div> */}
          {/* uttag third step , to scan qr code  */}
          {/* <div className="left-Menuse right-Menuse5">
            <div className="step-Wrapse">
              <div className="step-Topse">
                <p>TA UT DINA PENGAR ENKELT, SNABBT OCH SÄKERT!</p>
              </div>
              <div className="step-Secondse rht-Second2">
                <h3>
                  BEKRÄFTA DITT <br />
                  UTTAG MED BANKID
                </h3>
                <p>
                  Starta BankID-appen på din telefon och tryck
                  <br />
                  på ikonen för QR-kod i appen för att skanna
                  <br />
                  QR-koden på den här sidan.
                </p>
              </div>
              <div className="bar-Codese">
                <img src="assets/images/barcode.png" alt="..." />
              </div>
              <div className="input-Grupse3">
                <ul>
                  <li>
                    <a>Abvryt </a>
                  </li>
                  <li>
                    {" "}
                    <a>Behover du hjelp? </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </header>
    </div >
  );
};

const mapStateToProps = (state, props) => {
  return {
    showApprovePopup: props.showApprovePopup || false,
    userToken: state.common.userToken,
    userData: state.common.userData,
    walletData: state.wallet.walletData,
    isSidePanelClassname: state.common.sidepanelClassName,
    verificationDocDetails: state.verification.verificationDocument,
    verificationDynamicContent: state.common.sideBarMenuContent && state.common.sideBarMenuContent[SIDEBAR_CONTENT_TYPES.VERIFICATION] || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutRequest: (payload, callback) => dispatch(logoutRequest(payload, callback)),
    uploadDocument: (payload) => dispatch(uploadDocument(payload)),
    sendDocumentsToVerify: (payload) =>
      dispatch(sendDocumentsToVerify(payload)),
    getCasinosListForVerification: (payload) =>
      dispatch(getCasinosListForVerification(payload)),
    resetSidePanel: (payload) => dispatch(resetSidePanel(payload)),
    updateVerifyDocumentDetails: (payload) =>
      dispatch(updateVerifyDocumentDetails(payload)),
    updateHistoryList: (payload) => dispatch(updateHistoryList(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
