import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";

import {
  IMAGES,
  TEXT_CONST,
  check_hetu_string,
  SIDEBAR_CONTENT_TYPES,
} from "../../../shared";
import { defaultConfig } from "../../../config/default";
import {
  withdrawalRequest,
  getUserWallet,
  getLastMoneyPaidList,
  setWithdrawalAmount,
} from "../../../redux/actions";
import "./style.scss";
import BankList from "./banksList";
import WithdrawalApprove from "./withdrawalApprove";

const Uttag = ({
  walletData = {},
  withdrawalData = {},
  withdrawalRequest = () => { },
  close = () => { },
  withdrawalDynamicContent = {},
  withdrawalApprovalDynamicContent = {},
  showApprovePopup = false,
  setWithdrawalAmount = () => { },
  withdrawalAmount = ""
}) => {
  const [amount, setAmount] = useState(withdrawalAmount);
  const [isSuccess, setSuccess] = useState(false);
  const [approvePopup, setApprovePopup] = useState(showApprovePopup);
  const [ssn, setSSN] = useState("");
  const [isError, setError] = useState(false);
  const [showBankList, setShowBankList] = useState(false);
  const [ssnError, setSSNError] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [withdrawalUpperContent, setWithdrawalUpperContent] = useState("");

  const handleOnChange = (e, name) => {
    let { value } = e.target;
    if (name == "amount") {
      if (!value || parseFloat(value) == 0) {
        setError(true);
        setErrorMessage(TEXT_CONST.AMOUNT_REQUIRED);
        setAmount("");
        setWithdrawalAmount(0);
      } else if (value > walletData.amount) {
        setError(true);
        setErrorMessage(TEXT_CONST.AMOUNT_INAPPROPRIATE);
        setAmount("");
        setWithdrawalAmount(0);
      } else if (value > 2000) {
        setError(true);
        setErrorMessage(TEXT_CONST.AMOUNT_LIMIT);
      } else if (value.indexOf(".") !== -1) {
        const isSet = value.split(".")[1].length > 2;
        if (isSet) {
          setError(true);
          setErrorMessage(TEXT_CONST.AMOUNT_PERCISION);
          setAmount("");
          setWithdrawalAmount(0);
        } else {
          setError(false);
          setErrorMessage("");
          setAmount(value);
          setWithdrawalAmount(value);
        }
      } else {
        setError(false);
        setErrorMessage("");
        setAmount(value);
        setWithdrawalAmount(value);
      }
    }
    if (name == "ssn") {
      let SSN = value.trim();
      if (defaultConfig.LOCATION == "SE") {
        if (SSN.replace(/[^A-Z0-9]/gi, "").length < 12) {
          setSSNError((err) => ({ ...err, ssn: TEXT_CONST.SSN_INVALID }));
          return;
        } else {
          setSSNError((err) => ({ ...err, ssn: "" }));
        }
      } else {
        if (!check_hetu_string(SSN)) {
          setSSNError((err) => ({ ...err, ssn: TEXT_CONST.SSN_INVALID }));
          return;
        } else {
          setSSNError((err) => ({ ...err, ssn: "" }));
        }
      }
      setSSN(SSN);
    }
  };
  const handleWithdrawalRequest = () => {
    if (!amount || parseFloat(amount) == 0) {
      setError(true);
      setErrorMessage(TEXT_CONST.AMOUNT_REQUIRED);
      setAmount("");
      setWithdrawalAmount(0);
      return;
    } else if (amount < 20) {
      setError(true);
      setErrorMessage(TEXT_CONST.AMOUNT_MIN_LIMIT);
      setAmount("");
      setWithdrawalAmount(0);
      return;
    } else if (amount > 2000) {
      setError(true);
      setErrorMessage(TEXT_CONST.AMOUNT_LIMIT);
      setAmount("");
      setWithdrawalAmount(0);
      return;
    }

    withdrawalRequest({ amount }, (result) => {
      if (result.success) {
        // getUserWallet(() => { });
        // setSuccess(true);
        // enqueueSnackbar(TEXT_CONST.WITHDRAWAL_SUCCESS, { variant: "success" });
        // getLastMoneyPaidList({});
        setShowBankList(true);
        return;
      } else if (Object.keys(result).length) {
        window.location.replace(result.user_form_url);
      } else enqueueSnackbar(TEXT_CONST.WITHDRAWAL_ERROR, { variant: "error" });
    });
  };

  const onClosePopup = () => {
    close();
    setSuccess(false);
    setAmount("");
    setWithdrawalAmount(0);
    setShowBankList(false);
  };

  useEffect(() => {
    let str = withdrawalDynamicContent?.walletContent?.upper;
    str =
      str &&
      str.replace(`{{amount}}`, `${walletData.currency}${walletData.amount} `);
    setWithdrawalUpperContent(str);
  });

  // Communication between iframe and parent component.
  useEffect(() => {
    window.addEventListener("message", handleIframeTask);
    if (showApprovePopup) {
      window.parent.postMessage("close", "*");
    }
  }, [showApprovePopup]);

  let handleIframeTask = (e) => {
    if (e.data === "close") {
      setShowBankList(false);
      setSuccess(false);
      setApprovePopup(true);
    }
  };

  return (
    <div
      className={`left-Menuse sek-Popse open_side2 right-Menuse3 withdraw-approve ${showApprovePopup ? "approve-popup-screen" : ""
        }`}
    >
      <div className="step-Wrapse">
        <div className="step-Topse">
          <p
            dangerouslySetInnerHTML={{
              __html:
                withdrawalDynamicContent.title ||
                `VERIFIERA OCH FÅ PENGAR TILLBAKA`,
            }}
          ></p>
        </div>

        <div className="scroll_div">
          {!walletData.amount ? (
            <div className="step-Secondse pop-Step1">
              <h3
                dangerouslySetInnerHTML={{
                  __html:
                    withdrawalDynamicContent.emptyWalletMessage ||
                    `Du har inte pengar i ditt saldo riskfritt.se!`,
                }}
              ></h3>
            </div>
          ) : (
            <React.Fragment>
              {isSuccess && !showBankList && !approvePopup && (
                <div className="withdraw-success text-center">
                  <h3
                    className="color-green mb-3"
                    dangerouslySetInnerHTML={{
                      __html:
                        withdrawalDynamicContent.withdrawalSuccessTitle ||
                        `SUCCESS!`,
                    }}
                  ></h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        (withdrawalDynamicContent.withdrawalSuccessMessage &&
                          withdrawalDynamicContent.withdrawalSuccessMessage.replace(
                            `{{amount}}`,
                            `${amount} ${walletData.currency}`
                          )) ||
                        `You have now successfully withdraw <span className="color-green">${amount} ${walletData.currency}</span> to your bank account, your money should arrive shortly.`,
                    }}
                  ></p>
                  <div className="utang-Popse">
                    <a
                      className="primary-Gbtn full-Width dark-Green"
                      onClick={onClosePopup}
                    >
                      {withdrawalDynamicContent.withdrawalSuccessCloseButtonLabel ||
                        "Close"}
                    </a>
                  </div>
                </div>
              )}
              {!isSuccess && !showBankList && !approvePopup && (
                <div className="step-Secondse pop-Step1">
                  <h3
                    dangerouslySetInnerHTML={{
                      __html:
                        withdrawalUpperContent ||
                        ` Du har <span>
                        ${" "}
                        ${walletData.currency}${walletData.amount} 
                      </span>${" "}
                  i ditt
                  <br />
                  saldo på Riskfritt.se!${" "}`,
                    }}
                  ></h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        withdrawalDynamicContent?.walletContent?.lower ||
                        `  Fyll i summan här nedanför som du vill ta ut till ditt privata
                      bankkonto. Överföring sker snabbt och säkert med
                      betalningsmetoden Zimpler, som använder sig av BankID för att
                      göra säkra utbetalningar direkt till ditt bankkonto på bara
                      några minuter!`,
                    }}
                  ></p>
                </div>
              )}
            </React.Fragment>
          )}
          {walletData.amount && !isSuccess && !showBankList && !approvePopup ? (
            <>
              <div className="withdrawal pt-4">
                <p
                  className="amount-input-label"
                  dangerouslySetInnerHTML={{
                    __html:
                      withdrawalDynamicContent.withdrawalMethodLabel ||
                      `withdrawal method`,
                  }}
                ></p>
                <div className="zimpler_bg mt-2">
                  <div className="bg_grey">
                    <div className="form-row align-items-center justify-content-center">
                      <div className="col-4">
                        <img src={IMAGES.ZIMPLER2} alt="Zimpler" />
                      </div>
                      <div className="col-8 text-right">
                        <p>
                          <strong>Zimpler</strong>
                        </p>
                        <p>
                          <span>
                            MIN 20{walletData.currency} | MAX{" "}
                            2000{walletData.currency}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sek-Wrapse">
                <div className="form-group amount-input">
                  <p className="d-flex align-items-center justify-content-between amount-input-label">
                    {withdrawalDynamicContent.amountFieldLabel ||
                      `Ange belopp i`}
                    .
                    <span className="badge-success">{`${walletData.amount} ${walletData.currency || ""}`}</span>
                  </p>
                  <input
                    className="form-control walletData_currency"
                    placeholder={`${walletData.amount} ${walletData.currency || ""}`}
                    value={amount}
                    type={"number"}
                    min={"20"}
                    max={"2000"}
                    onChange={(e) => handleOnChange(e, "amount")}
                  />
                  {isError ? (
                    <span className="error_msg text-danger amount-input-error">
                      {errorMessage}
                    </span>
                  ) : null}
                </div>
                {/* <div className="form-group amount-input">
                  <div className="ssn-input mt-2">
                    <p className="amount-input-label">Ange personnummer.</p>
                    <input
                      className="form-control"
                      placeholder={`Ange personnummer`}
                      // value={ssn}
                      type={"text"}
                      onChange={(e) => handleOnChange(e, "ssn")}
                    />
                    {ssnError.ssn ? (
                      <span className="error_msg text-danger amount-input-error">
                        {ssnError.ssn}
                      </span>
                    ) : null}
                  </div>
                </div> */}
              </div>
              <div className="utang-Popse">
                <a
                  className="primary-Gbtn full-Width dark-Green"
                  onClick={!isError ? handleWithdrawalRequest : () => { }}
                >
                  {/* <i>
                    <img src={IMAGES.BANK_ID} alt="..." />
                  </i> */}
                  {withdrawalDynamicContent.buttonLabel || `BEKRÄFTA UTTAG`}
                </a>
              </div>
              <div className="brite-Lgsec">
                <img src={IMAGES.ZIMPLER2} alt="..." />
              </div>
            </>
          ) : null}
          {!isSuccess && showBankList && !approvePopup && (
            <BankList
              withdrawalData={withdrawalData}
              setShowBankList={setShowBankList}
            />
          )}
          {!isSuccess && approvePopup && !showBankList && (
            <WithdrawalApprove
              setSuccess={setSuccess}
              setApprovePopup={setApprovePopup}
              withdrawalApprovalDynamicContent={
                withdrawalApprovalDynamicContent
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    walletData: state.wallet.walletData,
    withdrawalData: state.wallet.withdrawalData,
    withdrawalDynamicContent:
      (state.common.sideBarMenuContent &&
        state.common.sideBarMenuContent[SIDEBAR_CONTENT_TYPES.WALLET]) ||
      {},
    withdrawalApprovalDynamicContent:
      (state.common.sideBarMenuContent &&
        state.common.sideBarMenuContent[
        SIDEBAR_CONTENT_TYPES.WITHDRAWAL_APPROVAL
        ]) ||
      {},
    showApprovePopup: props.showApprovePopup,
    withdrawalAmount: state.verification.withdrawalAmount
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    withdrawalRequest: (data, callback) => dispatch(withdrawalRequest(data, callback)),
    getUserWallet: (callback) => dispatch(getUserWallet(callback)),
    getLastMoneyPaidList: (payload) => dispatch(getLastMoneyPaidList(payload)),
    setWithdrawalAmount: (amount) => dispatch(setWithdrawalAmount(amount))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Uttag);
