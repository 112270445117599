import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import VerificationFileInput from "../VerificationFileInput";
import { SIDEBAR_CONTENT_TYPES, TEXT_CONST } from "../../../shared";
import { Select } from "../../atoms";
import "./style.scss";
const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const VerificationFirstStep = ({
  // setVerifySecondScreen,
  set_verificationData,
  verification_data,
  sendDocumentsForVerification,
  casinoList,
  isSucess = false,
  updateClass = () => { },
  fileSecond,
  fileFirst,
  set_fileFirst,
  set_fileSecond,
  // isClose,
  defaultCasino,
  verificationDynamicContent = {},
  walletData = {},
  setDefaultCasino = () => { }
}) => {
  // const [fileFirst, set_fileFirst] = useState();
  // const [fileSecond, set_fileSecond] = useState();
  // const [defaultAmount, setDefaultAmount] = useState();
  const [optionsCasino, set_optionsCasino] = useState([]);
  const [disable, set_disable] = useState(false);
  const [errors, set_errors] = useState({});
  const [verificationObj, setVerificationObj] = useState({});

  useEffect(() => {
    let {
      title,
      verificationStatusValue,
      verificationContent,
      casinoFieldLabel,
      noCasinoFoundText,
      approvedCasinoText,
      casinoFieldPlaceholder,
      compensationFieldLabel,
      compensationFieldPlaceholder,
      depositAmountImageUpload,
      lostAmountImageUpload,
      buttonLabel,
    } = verificationDynamicContent || {};

    let obj = {
      title: title || "VERIFIERA OCH FÅ PENGAR TILLBAKA",
      verificationContent:
        verificationContent ||
        `För att vi ska kunna betala tillbaka summan du förlorade på din första insättning på ett valfritt casino
      från vår topplista så lär vi på Riskfritt.se först bekräfta att du är en ny kund på casinot och att du hade
      oturen att förlora din första insättning, vänligen följ stegen nedanför så får du en notifikation när vi har
      verifierat dina uppladdade dokument. `,
      verificationStatusValue: verificationStatusValue || "Pending",
      casinoFieldLabel: casinoFieldLabel || `<span>1.</span> Vilket casino gjorde <br /> du din första insättning på?`,
      approvedCasinoText: approvedCasinoText || "Approved",
      noCasinoFoundText: noCasinoFoundText || TEXT_CONST.NO_OPTION,
      casinoFieldPlaceholder: casinoFieldPlaceholder || `Select Casino`,
      compensationFieldLabel:
        compensationFieldLabel ||
        `<span>2.</span> Ersättning du kan <br />
      få tillbaka fylls i automatiskt`,
      compensationFieldPlaceholder:
        compensationFieldPlaceholder ||
        `Välj casino och se hur mycket du kan få tillbaka`,
      buttonLabel: buttonLabel || "Verifiera och skicka in",
      labelForDepositImg:
        depositAmountImageUpload?.label ||
        ` <span>3.</span>Ladda upp en bild på
      <br />
      att du har gjort en insättning`,
      contentForDepositImg:
        depositAmountImageUpload?.content ||
        ` Logga in på ditt konto på det utvalda casinot. Gå till dina transaktioner och ta en bild på att du har gjort en insättning.`,
      contentBeforeSelectForDepositImg:
        depositAmountImageUpload?.contentBeforeSelect ||
        `<h3>
      Dra filer hit eller <span>klicka här för att Bläddra</span>
    </h3>
    <p>
      Vi accepterar endast .jpg eller .png filer.
      <br />
      Filerna får inte vara större än 2 MB.
    </p>`,
      fileNameLabelForDepositImg:
        depositAmountImageUpload?.contentAfterSelect?.fileNameLabel,
      statusLabelForDepositImg:
        depositAmountImageUpload?.contentAfterSelect?.statusLabel,
      alternativeLabelForDepositImg:
        depositAmountImageUpload?.contentAfterSelect?.alternativeLabel,

      labelForLostImg:
        lostAmountImageUpload?.label ||
        `<span>4.</span> Ladda upp en bild på
      <br />
      att du har förlorat pengarna`,
      contentForLostImg:
        lostAmountImageUpload?.content ||
        ` Logga in på ditt konto på det utvalda casinot. Gå till dina transaktioner och ta en bild på att du har
      förlorat pengarna.`,

      contentBeforeSelectForLostImg:
        lostAmountImageUpload?.contentBeforeSelect ||
        ` <h3>
      Dra filer hit eller <span>klicka här för att Bläddra</span>
    </h3>
    <p>
      Vi accepterar endast .jpg eller .png filer.
      <br />
      Filerna får inte vara större än 2 MB.
    </p>`,
      fileNameLabelForLostImg:
        lostAmountImageUpload?.contentAfterSelect?.fileNameLabel,
      statusLabelForLostImg:
        lostAmountImageUpload?.contentAfterSelect?.statusLabel,
      alternativeLabelForLostImg:
        lostAmountImageUpload?.contentAfterSelect?.alternativeLabel,
    };
    setVerificationObj(obj);
  }, [Object.keys(verificationDynamicContent).length]);

  useEffect(() => {
    if (verification_data.casino_name) {
      let result = casinoList.filter(
        (item) => item._id == verification_data.casino_name
      );

      set_verificationData({
        ...verification_data,
        refunded_amount: result[0]?.compensation ? result[0]?.compensation : 0,
      });
    }
  }, [verification_data.casino_name]);

  useEffect(() => {
    if (fileFirst && fileSecond && verification_data.casino_name) {
      set_disable(true); //class for button change
    }
  }, [verification_data, fileFirst, fileSecond]);

  useEffect(() => {
    if (isSucess) {
      setTimeout(() => {
        updateClass("");
      }, 1500);

      set_fileFirst("");
      set_fileSecond("");
    }
  }, [isSucess]);

  useEffect(() => {
    let arr = [];
    casinoList &&
      casinoList.map((item) => {
        let obj = {
          value: item._id,
          label: (
            <div className="select_img" key={item._id}>

              {/* {item.approvedDocsCount ? 
                  <div className="tprib-Mainse red">
                    <div className="tprib-Angse">
                      <i className="fa fa-caret-down" aria-hidden="true" style="color: #1eae50"></i>{" "}
                      <i className="fa fa-caret-down ryt-Se" aria-hidden="true" style="color: #1eae50"></i>{" "}
                    </div>
                    <div className="tprib-text">
                      <span>{verificationObj.approvedCasinoText}</span>
                    </div>
                    </div> : null} */}


              {item.approvedDocsCount ?
                <div className="approved">
                  <div className="approved_icon">
                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                    <i className="fa fa-caret-down ryt-Se" aria-hidden="true"></i>
                  </div>
                  <div className="approved-text">
                    <span>{verificationObj.approvedCasinoText}</span>
                  </div>
                </div> : null}
              {item.featuredImage ? (
                <div className="casino-image" style={{ backgroundColor: item.logoBackgroundColor || 'transparent' }}>
                  <img
                    src={`${REACT_APP_API_BASE_URL}${item.featuredImage}`}
                    height="12px"
                    width="12px"
                  />
                </div>
              ) : null}
              <p id="casinoTitle">{item.title}</p>
            </div>
          ),
        };
        arr.push(obj);
      });
    set_optionsCasino(arr);
  }, [casinoList]);

  let filterOption = ({ label, value, data }, string) => {
    if (string) {
      let children = label?.props?.children;
      let path = children[children.length - 1]?.props;
      return path?.children?.toLowerCase()?.includes(string && string.toLowerCase());
    } else {
      return true;
    }
  };

  const onSubmit = () => {
    if (
      fileFirst &&
      fileSecond &&
      verification_data.casino_name &&
      verification_data.refunded_amount
    ) {
      sendDocumentsForVerification(
        fileFirst,
        fileSecond,
        verification_data.casino_name,
        verification_data.refunded_amount
      );
    } else {
      checkvalidation();
    }
  };

  const checkvalidation = () => {
    if (!verification_data.casino_name) {
      set_errors({ ...errors, casino: "*Vaaditaan" });
      return;
    } else if (!verification_data.refunded_amount) {
      set_errors({
        ...errors,
        casino: "*No compensation for selected casino.",
      });
      return;
    } else if (verification_data.casino_name) {
      set_errors({ ...errors, casino: "" });
    }
    if (!fileFirst) {
      set_errors({ ...errors, firstImage: "*Vaaditaan" });
      return;
    } else if (fileFirst) {
      set_errors({ ...errors, firstImage: "" });
    }
    if (!fileSecond) {
      set_errors({ ...errors, secondImage: "*Vaaditaan" });
      return;
    } else if (fileSecond) {
      set_errors({ ...errors, secondImage: "" });
    }
    return;
  };

  return (
    <div className="left-Menuse open_side2 right-Menuse2">
      <div className="step-Wrapse">
        <div className="step-Topse">
          <p dangerouslySetInnerHTML={{ __html: verificationObj.title }}></p>
        </div>

        <div className="scroll_div">
          <div className="step-Secondse">
            <p
              dangerouslySetInnerHTML={{
                __html: verificationObj.verificationContent,
              }}
            ></p>
          </div>
          <div className="input-Wrapse">
            <div className="input-Grupse">
              <label
                dangerouslySetInnerHTML={{
                  __html: verificationObj.casinoFieldLabel,
                }}
              ></label>

              <div className="casino-Selctmenu new-Msg">
                {/* todo: images from api for casinos  */}
                <Select
                  label="select"
                  options={optionsCasino}
                  placeholder={verificationObj.casinoFieldPlaceholder}
                  // isClose={isClose}
                  defaultVal={defaultCasino}
                  isSearchable={true}
                  onValueChange={(value) => {
                    set_verificationData({
                      ...verification_data,
                      casino_name: value,
                    });
                    set_errors({ ...errors, casino: "" });
                  }}
                  handleSelectedOption={(value) => setDefaultCasino(value)}
                  filterOption={filterOption}
                  noCasinoFoundText={verificationObj.noCasinoFoundText}
                />
                {errors.casino ? (
                  <span class="error text-danger">{errors.casino}</span>
                ) : null}
              </div>

              <div className="tab-content">
                <div className="input-Grupse">
                  <label
                    dangerouslySetInnerHTML={{
                      __html: verificationObj.compensationFieldLabel,
                    }}
                  ></label>
                </div>

                <div className="tab-pane active" id="settings0">
                  <div className="input-Grpsec">
                    <p style={{ color: "#919191" }}>
                      {
                        isNaN(verification_data && verification_data.refunded_amount)
                          ? verificationObj.compensationFieldPlaceholder
                          : `${verification_data && verification_data.refunded_amount} ${walletData.currency || "EUR"}`
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="input-Grupse">
              <label
                dangerouslySetInnerHTML={{
                  __html: verificationObj.labelForDepositImg,
                }}
              ></label>
              <p
                dangerouslySetInnerHTML={{
                  __html: verificationObj.contentForDepositImg,
                }}
              ></p>

              <div className="upld-Wrapse">
                <div className="top-Uptxtse">
                  {/* view before the  first image uploads */}
                  {!fileFirst && (
                    <div
                      className="top-Uptxt"
                      dangerouslySetInnerHTML={{
                        __html: verificationObj.contentBeforeSelectForDepositImg,
                      }}
                    ></div>
                  )}
                  {/* view after the first image uploads */}
                  <div
                    className="fileupload fileupload-new new-Msg file-Upld"
                    data-provides="fileupload"
                  >
                    <div className="btn-file neFs"></div>
                    <VerificationFileInput
                      errors={errors}
                      updateError={() => {
                        set_errors({ ...errors, firstImage: "" });
                      }}
                      name={fileFirst && fileFirst.name}
                      file={fileFirst}
                      set_File={set_fileFirst}
                      fileColumnName={verificationObj.fileNameLabelForDepositImg}
                      statusColumnName={verificationObj.statusLabelForDepositImg}
                      verificationStatusValue={verificationObj.verificationStatusValue}
                      optionsColumnName={verificationObj.alternativeLabelForDepositImg}
                    />
                  </div>
                </div>
                {errors.firstImage ? <span class="error text-danger">{errors.firstImage}</span> : null}
              </div>
              {errors.firstImage ? (
                <span class="error text-danger">{errors.firstImage}</span>
              ) : null}
            </div>
          </div>
          <div className="input-Grupse">
            <label
              dangerouslySetInnerHTML={{
                __html: verificationObj.labelForLostImg,
              }}
            ></label>
            <p
              dangerouslySetInnerHTML={{
                __html: verificationObj.contentForLostImg,
              }}
            ></p>
            <div className="upld-Wrapse">
              <div className="top-Uptxtse">
                {/* view before the second image uploads */}
                {!fileSecond && (
                  <div
                    className="top-Uptxt"
                    dangerouslySetInnerHTML={{
                      __html: verificationObj.contentBeforeSelectForLostImg,
                    }}
                  ></div>
                )}
                {/* view after the second image uploads */}
                <div
                  className="fileupload fileupload-new new-Msg file-Upld"
                  data-provides="fileupload"
                >
                  <div className=" btn-file neFs">
                    <VerificationFileInput
                      errors={errors}
                      name={fileSecond && fileSecond.name}
                      file={fileSecond}
                      set_File={set_fileSecond}
                      updateError={() => {
                        set_errors({ ...errors, secondImage: "" });
                      }}
                      fileColumnName={verificationObj.fileNameLabelForLostImg}
                      statusColumnName={verificationObj.statusLabelForLostImg}
                      optionsColumnName={verificationObj.alternativeLabelForLostImg}
                      verificationStatusValue={verificationObj.verificationStatusValue}
                    />
                  </div>
                </div>
              </div>
              {errors.secondImage ? <span class="error text-danger">{errors.secondImage}</span> : null}
            </div>
            {errors.secondImage ? (
              <span class="error text-danger">{errors.secondImage}</span>
            ) : null}
          </div>

          <div
            className="input-Grupse"
            onClick={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            {" "}
            <a
              className="primary-Gbtn full-Width gray-Btn"
              style={{ backgroundColor: disable ? "#65c82d" : "#b2b6b2" }}
            >
              {verificationObj.buttonLabel}
            </a>{" "}
            <div className="input-Grupse color-green text-center thx-message">
              {isSucess ? <p>Kiitos asiakirjojen lähettämisestä</p> : null}
            </div>
          </div>
        </div>
      </div >
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    walletData: state.wallet.walletData,
    verificationDynamicContent:
      state.common.sideBarMenuContent && state.common.sideBarMenuContent[SIDEBAR_CONTENT_TYPES.VERIFICATION] || {},
  };
};

export default connect(mapStateToProps)(VerificationFirstStep);
