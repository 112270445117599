import React, { Component, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useSnackbar } from "notistack";
import "./styles.scss";

import { getUserWallet } from "../../../redux/actions";
import { TEXT_CONST, IMAGES, ROUTES_SE, ROUTES_FI, ROUTE_CONSTANTS } from "../../../shared";
import NotificationModal from "../NotifcationModal";
import { updateAuthToken } from "../../../helpers";

const MenuList = ({
  toggleMenu,
  showProfileModal,
  updateClass,
  showMenu,
  logoutRequest,
  name,
  getUserWallet,
  walletData,
  history,
  unreadMsgCount,
  notifications = [],
  sideBarMenuList = {},
  showApprovePopup = false
}) => {
  let [show, setShowMenu] = useState(showMenu);

  const wrapperRef = useRef(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function handleClickOutside(event) {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  }

  useEffect(() => {
    getUserWallet(() => { });
  }, []);

  useEffect(() => {
    // Check if the user is on the withdrawal approval screen.
    if (showApprovePopup) {
      updateClass("open_side2");
      setShowMenu(!show)
    } else {
      updateClass("");
      setShowMenu(false)
    }
  }, [showApprovePopup]);

  return (
    <div
      className="collapse01 navbar-collapse justify-content-end"
      id="header-menu-section"
    >
      <ul>
        {notifications.length ? (
          <Popup
            trigger={
              <li className="nav-item notification-icon">
                {" "}
                <a className="nav-link">
                  {" "}
                  <img src={IMAGES.NOTIFICATION} alt="Notification" />{" "}
                  <span className="not-Ficse m-Block">
                    {unreadMsgCount ? unreadMsgCount : 0}
                  </span>{" "}
                </a>{" "}
              </li>
            }
            position="bottom"
            on="click"
            closeOnDocumentClick
            closeOnEscape
            arrow={true}
            className="notification-popup-root"
          >
            <NotificationModal />
          </Popup>
        ) : (
          <li
            className="nav-item notification-icon"
            onClick={() => {
              closeSnackbar();
              enqueueSnackbar(TEXT_CONST.NOTIFICATION_INBOX_EMPTY, {
                variant: "info",
              });
            }}
          >
            {" "}
            <a className="nav-link">
              {" "}
              <img src={IMAGES.NOTIFICATION} alt="Notification" />{" "}
              <span className="not-Ficse m-Block">
                {unreadMsgCount ? unreadMsgCount : 0}
              </span>{" "}
            </a>{" "}
          </li>
        )}

        <li className="nav-item ">
          {" "}
          <a className="nav-link" onClick={() => updateClass("open_side2")}>
            <div class="wallet_value"><i>{`${walletData.currency || ""}`}</i>
              {(walletData.amount && walletData.amount.toFixed(0)) || "0"}{" "}</div>
            <img src={IMAGES.WALLET} alt="Wallet" />
          </a>{" "}
        </li>
        <li
          className={`nav-item dropdown ${show ? "show" : ""}`}
          ref={wrapperRef}
        >
          <a
            onClick={() => setShowMenu(!show)}
            className="nav-link dropdown-toggle"
            id="navbarDropdown"
            data-toggle="dropdown"
          >
            <span className="d-none d-lg-flex">{name} </span>
            <img src={IMAGES.PROFILE} alt="Profile_Pic" />
            <span className={`fa fa-chevron-${show ? "up" : "down"}`}></span>
          </a>
          <div className={`dropdown-menu ${show ? "show" : ""}`}>
            <a
              onClick={() => {
                toggleMenu(!show);
                setShowMenu(!show);
                showProfileModal(true);
              }}
              className="dropdown-item"
            >
              {sideBarMenuList.updateProfileLabel || TEXT_CONST.MY_PROFILE}
            </a>
            <a
              onClick={() => {
                updateClass("open_side1");
                setShowMenu(!show);
              }}
              className="dropdown-item"
            >
              {sideBarMenuList.verificationLabel || TEXT_CONST.VERIFICATION}
            </a>
            <a
              onClick={() => {
                updateClass("open_side2");
                setShowMenu(!show);
              }}
              className="dropdown-item"
            >
              {sideBarMenuList.walletLabel || TEXT_CONST.UTTAG}
            </a>
            <a onClick={() => {
              updateClass("open_side3");
              setShowMenu(!show);
            }}
              className="dropdown-item">
              {sideBarMenuList.historyLabel || TEXT_CONST.HISTORY}
            </a>
            {/* <Link className="dropdown-item" to={ROUTES_FI.HISTORY}>
              {sideBarMenuList.historyLabel || TEXT_CONST.HISTORY}
            </Link> */}
            <a
              onClick={() => logoutRequest({}, cb => {
                history.push(ROUTE_CONSTANTS.ROOT);
                updateAuthToken("");
              })}
              className="dropdown-item"
            >
              {sideBarMenuList.logoutLabel || TEXT_CONST.LOGOUT}
            </a>
          </div>
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    walletData: state.wallet.walletData || {},
    unreadMsgCount: state.common.unreadMsgCount,
    notifications: state.common.notificationDataForList,
    sideBarMenuList: state.common.sideBarMenuList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserWallet: (callback) => {
      dispatch(getUserWallet(callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuList);
