import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { useSnackbar } from "notistack";

import "./style.scss";
import clock_icon from "../../../assets/images/clock_icon.svg";
import noti_icon from "../../../assets/images/noti-fi.svg";
import {
  clearAllNotifications,
  getAllNotifications,
} from "../../../redux/actions";
import { TEXT_CONST, SERVER_URL } from "../../../shared";
import { defaultConfig } from "../../../config/default";

const NotificationModal = ({
  clearAllNotifications,
  notifications = [],
  getAllNotifications,
  notificationScreenContent = {}
}) => {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => { }, []);

  const clearAll = () => {
    clearAllNotifications((success) => {
      if (!success) {
        enqueueSnackbar(TEXT_CONST.NOTIFICATION_CLEAR_ERROR, {
          variant: "error",
        });
      } else {
        getAllNotifications();
        enqueueSnackbar(TEXT_CONST.NOTIFICATION_CLEAR_SUCCESS, {
          variant: "success",
        });
      }
    });
  };

  return (
    <React.Fragment>
      {!notifications.length ? null : (
        <div className="notification-modal">
          <div className="not_hdr p-2">
            <div className="row align-items-center notification-head">
              <div className={"col-md-8"}>
                <h5><i className="mr-2"><img src={noti_icon} alt="" width="25" /></i>{notificationScreenContent.messagesLabel || "Meddelanden"}</h5>
              </div>
              <div className="col-md-4 clear_all" onClick={clearAll}>
                {notificationScreenContent.clearAllLabel || "Rensa alla"}
              </div>
            </div>
          </div>

          <ul className="notification-list">
            {notifications.map((notification, idx) => (
              <li
                className={`list-group-item list-group-item-action  ${!notification.msgRead ? "unread-msg" : ""}`}
                key={idx}
              >
                <div className="notification-info">
                  <div className="row notification-list-user-block">
                    {notification.image ? (
                      <div className="col-md-4 col-4">
                        <div className="img-pr" style={{ background: notification?.additionalInfo?.logoBackgroundColor || "#311d46" }}>
                          <img src={`${SERVER_URL}${notification.image}`} alt="" />
                        </div>
                      </div>
                    ) : null}

                    <div
                      className={`${notification.image
                        ? "col-md-8 col-8"
                        : "col-md-12 col-12 notifi_noimage"
                        }`}
                    >
                      <div className="d-flex align-items-start justify-content-start flex-column">
                        <div className="notification-list-user-name">
                          <h6 style={{ fontSize: '15px' }}>
                            {notification.additionalInfo && notification.additionalInfo.bonusButtonTitle ? "" : notification.title}
                          </h6>
                        </div>
                        {/* <div className="notification-date">
                          <i className="mr-1">
                            <img src={clock_icon} alt="" width="13" />
                          </i>{" "}
                          {moment(notification.createdAt).format("DD-MM-YYYY")}{" "}
                          <span>
                            at{" "}
                            {moment(notification.createdAt).format("hh:mm a")}
                          </span>
                        </div> */}
                      </div>
                      <div>
                        <div className="notification-body">
                          {!notification.additionalInfo && (notification.body)}
                          {notification.additionalInfo && notification.additionalInfo.bonusButtonTitle && (
                            <div className="d-flex flex-column w-100 not_btns">
                              <Link
                                className="primary-Gbtn"
                                style={{ marginTop: "3px" }}
                                to={`${notification.additionalInfo.affiliateKey && `/${(defaultConfig.LOCATION).toLowerCase()}/go/${notification.additionalInfo.affiliateKey}`}`}>
                                {notification.additionalInfo.bonusButtonTitle}
                              </Link>
                              <Link
                                className="orange-Btn"
                                to={`/${(defaultConfig.LOCATION).toLowerCase()}/notifications`}
                                style={{ marginTop: "3px" }}>
                                {notificationScreenContent.readMoreLabel || "Läs mer"}
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>

          <ul>
            <li className="list-footer">
              <Link to={`/${(defaultConfig.LOCATION).toLowerCase()}/notifications`}>
                {notificationScreenContent.seeMoreLabel || "Se mer"}
              </Link>
            </li>
          </ul>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    notifications: state.common.notificationDataForList,
    notificationScreenContent: state.common.notificationContent
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearAllNotifications: (callback) =>
      dispatch(clearAllNotifications(callback)),
    getAllNotifications: () => dispatch(getAllNotifications()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationModal);
