import { REHYDRATE } from "redux-persist";
import {
  GET_USER_DATA_SUCCESS,
  RESET,
  START_LOADING,
  STOP_LOADING,
  SET_DEVICE_TOKEN,
  UPDATE_REDUCER_AUTH_TOKEN,
  OPEN_SIDEPANEL,
  RESET_SIDEPANEL,
  SET_UNREAD_MSG_COUNT,
  SET_NOTIFICATION_DATA,
  SET_NOTIFICATION_DATA_FOR_LIST_POPUP,
  SET_PROFILE_DATA,
  SET_POPUP_CONTENT_DATA,
  SET_SIDEBAR_MENU_LIST,
  SET_SIDEBAR_MENU_CONTENT,
  SET_NOTIFICATION_SCREEN_CONTENT,
  SET_LOCATION_DATA,
} from "../actions";
import { updateAuthToken } from "../.././helpers";
import { SET_FOOTER_LINKS_DATA } from "../actions/footer";
import { SET_HEADER_CONTENT } from "../actions/dynamicContent";

const initialCommonState = {
  deviceToken: "",
  userToken: "",
  userData: {},
  loading: false,
  sidepanelClassName: "",
  unreadMsgCount: 0,
  notificationData: [],
  notificationDataForList: [],
  footerLinksData: {
    upper: [],
    lower: [],
  },
  dynamicContent: {},
  sideBarMenuList: {},
  sideBarMenuContent: {},
  notificationContent: {}
};

const CommonReducer = (state = { ...initialCommonState }, action) => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case SET_DEVICE_TOKEN:
      return {
        ...state,
        deviceToken: action.data,
      };
    case GET_USER_DATA_SUCCESS:
      return {
        ...state,
        userData: { ...state.userData, ...action.payload },
      };
    case SET_PROFILE_DATA:
      return {
        ...state,
        profileData: action.payload,
      };
    case UPDATE_REDUCER_AUTH_TOKEN:
      return {
        ...state,
        userToken: action.payload,
      };
    case RESET:
      return {
        ...state,
        deviceToken: "",
        userToken: "",
        userData: {}
      };
    case OPEN_SIDEPANEL:
      return {
        ...state,
        sidepanelClassName: action.className,
      };
    case RESET_SIDEPANEL:
      return {
        ...state,
        sidepanelClassName: "",
      };
    case SET_UNREAD_MSG_COUNT:
      return {
        ...state,
        unreadMsgCount: action.data,
      };
    case SET_NOTIFICATION_DATA:
      return {
        ...state,
        notificationData: action.data,
      };
    case SET_NOTIFICATION_DATA_FOR_LIST_POPUP:
      return {
        ...state,
        notificationDataForList: action.data,
      };
    case SET_FOOTER_LINKS_DATA:
      return {
        ...state,
        footerLinksData: { ...state.footerLinksData, ...action.data },
      };
    case SET_HEADER_CONTENT:
      return {
        ...state,
        dynamicContent: action.data,
      };
    case SET_LOCATION_DATA:
      return {
        ...state,
        locationData: action.data,
      };
    case SET_POPUP_CONTENT_DATA:
      return {
        ...state,
        popupContent: action.data,
      };
    case SET_SIDEBAR_MENU_LIST:
      return {
        ...state,
        sideBarMenuList: action.data,
      };
    case SET_SIDEBAR_MENU_CONTENT:
      return {
        ...state,
        sideBarMenuContent: action.data,
      };
    case SET_NOTIFICATION_SCREEN_CONTENT:
      return {
        ...state,
        notificationContent: action.data,
      };
    case REHYDRATE:
      let common = ((action || {}).payload || {}).common || initialCommonState;
      updateAuthToken(common.userToken);

      return {
        ...initialCommonState,
        userToken: common.userToken,
        dynamicContent: common.dynamicContent,
        popupContent: common.popupContent,
        sideBarMenuList: common.sideBarMenuList,
        sideBarMenuContent: common.sideBarMenuContent,
        notificationContent: common.notificationContent,
        ...(action.payload || {}).common,
      };
    default:
      return state;
  }
};

export default CommonReducer;
