import React, { useEffect } from "react";
import {
  CasinoAccordionList,
  Footer,
  Header,
  HomeBanner,
  HowItWorks,
  LatestMembers,
  NewsList,
  QA_Accordion,
} from "../../../../components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.scss";

const HomeScreen = ({
  location,
  history,
  showLogin,
  showRegister,
  userToken,
  isLandingPage,
  showApprovePopup = false
}) => {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <React.Fragment>
      <div className="main-content">
        <Header showApprovePopup={showApprovePopup} />
        <HomeBanner
          data={location.state || {}}
          showLogin={showLogin}
          showRegister={showRegister} />
        <div className="mid-Mainsec" id="home_detail">
          <div className="container">
            <HowItWorks />
            {!isLandingPage && <CasinoAccordionList />}
            <NewsList history={history} />
            <LatestMembers />
            <QA_Accordion />
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default HomeScreen;
