import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Toast } from "react-bootstrap";
import { getToken, onMessageListener } from "../../../firebase.js";
import {
  setDeviceToken,
  getAllNotifications,
} from "../../../redux/actions/index.js";
import "./style.scss";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const NotificationToast = ({
  setUserDeviceToken = () => { },
  userToken = "",
  getAllNotifications = () => { },
}) => {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({});

  const setDeviceToken = (deviceToken) => {
    setUserDeviceToken(deviceToken);
  };

  onMessageListener()
    .then((payload) => {
      setShow(true);
      getAllNotifications();
      setNotification({
        title: payload?.data?.title,
        body: payload?.data?.body,
        image: payload?.data?.image,
        click_action: payload?.data?.redirectTo,
        data: payload?.data || {}
      });
    })
    .catch((err) => console.log("failed: ", err.message));

  useEffect(() => {
    userToken && getAllNotifications();
    getToken(setDeviceToken);
  }, []);

  return (
    <React.Fragment>
      {!userToken ? (
        <React.Fragment> </React.Fragment>
      ) : (
        <Toast
          onClick={() => notification.click_action ? window.open(notification.click_action, "_blank") : {}}
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
          animation
        >
          <Toast.Header>
            {notification.image ?
              <img
                style={{ backgroundColor: notification.data?.logoBackgroundColor || "transparent" }}
                src={`${BASE_URL}${notification.image}`}
                className="rounded mr-2"
                alt="featuredImage"
                width="50"
                height="50"
              />
              :
              <i className="fa fa-info-circle" aria-hidden="true"></i>
            }
            <strong className="mr-auto">
              {notification.title}
            </strong>
            <small>{notification.date}</small>
          </Toast.Header>
          {notification.body ?
            <Toast.Body>
              {notification.body.length > 150
                ? `${notification.body.substring(0, 150)}...`
                : notification.body}
            </Toast.Body>
            : null}
        </Toast>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  let { userToken } = state.common;
  return { userToken };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserDeviceToken: (token) => dispatch(setDeviceToken(token)),
    getAllNotifications: (filterByDate) =>
      dispatch(getAllNotifications(filterByDate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationToast);
