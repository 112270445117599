import { Accordion } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Link, useHistory } from "react-router-dom";


import "./styles.scss";
import { CassinoMoreDetails } from "../../atoms";
import defaultImg from "../../../assets/images/no-image.png";
import { ROUTES_FI, ROUTE_CONSTANTS } from "../../../shared";
import { defaultConfig } from "../../../config/default";

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const CasinoAccordionItem = ({
  item,
  isCollapse = true,
  active,
  index,
  onClick = () => { },
  isReviewPage,
  userToken
}) => {
  let history = useHistory();

  const [paymentCollapsed, setPaymentCollapsed] = useState(true);

  let array = [];
  let data =
    item &&
    item.affiliatedFields &&
    item.affiliatedFields.reviewRatings &&
    item.affiliatedFields.reviewRatings.affiliatedRating;

  let { ribbonBackgroundColor, ribbonTextColor, ribbonBorderColor, logoBackgroundColor, reviewRatingBackgroundColor } =
    (item && item.affiliatedFields && item.affiliatedFields.colorSettings) || "";
  let { ribbionText } = (item && item.affiliatedFields && item.affiliatedFields.textContent) || "";
  let { ratingsValue } = (item && item.affiliatedFields && item.affiliatedFields.reviewRatings) || 0;
  let { listOfInformations, Above18AgeAndApply, } =
    (item && item.affiliatedFields && item.affiliatedFields.reviewMainFields) || "";
  let { bonusPromoTitle, bonusValue, bonusPrimaryTitle, freeSpinsPromoText, primaryTitleSpins, numberOfFreeSpins, bonusButtonSubTitle, bonusButtonTitle, viewDetailsLabelText } =
    (item && item.affiliatedFields && item.affiliatedFields.reviewMainFields) || "";
  let { affiliatedLink } = (item && item.affiliatedFields && item.affiliatedFields.reviewMainFields) || {};
  let { linksList } = (item && item.affiliatedFields.reviewFooterFields) || {};
  let paymentLogos = (item && item.affiliatedFields.paymentLogos) || {};
  let paymentGatewaysOrder = (item && item.affiliatedFields.paymentGatewaysOrder) || [];
  let acceptedCountries = (item && item.affiliatedFields.acceptedCountries) || {};

  // let isFloatRating = ratingsValue &&
  //   ratingsValue.toString().includes(".") &&
  //   ratingsValue.toString().substr(ratingsValue.toString().indexOf(".") + 1, ratingsValue.toString().length) != "0";
  let ratingsInPercent = (ratingsValue * 100) / 5;

  useEffect(() => {
    if (history.location.pathname.includes(ROUTES_FI.REVIEW)) {
      window.scrollTo(0, 0);
    }
    if (!isCollapse) {
      setPaymentCollapsed(isCollapse);
    }
  }, [isCollapse]);

  return (
    <Accordion defaultActiveKey="0" key={item ? item._id : ""}>
      <div className="tpbox-Wrapse" id="tpbox-Wrapse-id" key={item ? item._id : ""}>
        <div className="tprib-Mainse red">
          <div className="tprib-Angse">
            <i className="fa fa-caret-down" aria-hidden="true" style={{ color: ribbonBackgroundColor }}></i>{" "}
            <i className="fa fa-caret-down ryt-Se" aria-hidden="true" style={{ color: ribbonBackgroundColor }}></i>{" "}
          </div>
          <div
            className="tprib-text"
            style={{
              backgroundColor: ribbonBackgroundColor,
              borderColor: ribbonBorderColor,
              color: ribbonTextColor ? ribbonTextColor : "white"
            }}
          >
            <p>{ribbionText}</p>
          </div>
        </div>
        <div className="list-Boxwrap">
          <div className="top-Listse">
            <div className="row no-gutters">
              <div className="col-lg-3">
                <div className="logo-Topse" style={{ backgroundColor: logoBackgroundColor }}>
                  <Link to={userToken ? (affiliatedLink && affiliatedLink.affiliateKey && `/${(defaultConfig.LOCATION).toLowerCase()}/go/${affiliatedLink.affiliateKey}`) : ROUTE_CONSTANTS.LOGIN}>
                    <img
                      src={item && item.featuredImage ? `${REACT_APP_API_BASE_URL}${item.featuredImage}` : defaultImg}
                      alt="Add casino image from admin panel"
                    />
                  </Link>
                  <div className="ratin-Boxse rat-Bg-2" style={{ backgroundColor: reviewRatingBackgroundColor }}>
                    <div className="star-rating">
                      <Link className="back-stars" to={
                        userToken
                          ? item && item.permalink && `/${(defaultConfig.LOCATION).toLowerCase()}${ROUTES_FI.REVIEW + "/" + item.permalink}`
                          : item && item.permalink && `${ROUTES_FI.REVIEW + "/" + item.permalink}`

                      }>
                        <i class="fa fa-star-o" aria-hidden="true"></i>
                        <i class="fa fa-star-o" aria-hidden="true"></i>
                        <i class="fa fa-star-o" aria-hidden="true"></i>
                        <i class="fa fa-star-o" aria-hidden="true"></i>
                        <i class="fa fa-star-o" aria-hidden="true"></i>

                        <div className="front-stars" style={{ width: ratingsInPercent }}>
                          <i class="fa fa-star" aria-hidden="true"></i>
                          <i class="fa fa-star" aria-hidden="true"></i>
                          <i class="fa fa-star" aria-hidden="true"></i>
                          <i class="fa fa-star" aria-hidden="true"></i>
                          <i class="fa fa-star" aria-hidden="true"></i>
                        </div>
                      </Link>
                    </div>
                    {/* {ratingsValue ? ( */}

                    {/* <ul>
                      
                      {
                        [1, 2, 3, 4, 5].map((data, index) => {
                          return (
                            <li key={index}>
                              {data <= ratingsValue &&
                                <a href={item && item.permalink && `${ROUTES_FI.REVIEW + "/" + item.permalink.permalink}` || "javascript:void(0);"} >
                                  <i className={`fa fa-star star-filled`} ></i>
                                </a>
                              }
                            </li>
                          );
                        })
                      }
                      {
                        isFloatRating &&
                        <a href="javascript:void(0);" style={{ "color": "#FFF", "fontSize": "12px", "margin": "0 2px" }}>
                          <i className={`fa fa-star star-half-filled`} ></i>
                        </a>
                      }
                      {
                        !ratingsValue ? [1, 2, 3, 4, 5].map((data, index) => {
                          return (
                            <li key={index}>
                              <a href="javascript:void(0);" >
                                <i className={`fa fa-star star-empty`} ></i>
                              </a>
                            </li>
                          );
                        })
                          : null
                      }
                    </ul> */}
                    {/* ) : (
                      <p style={{ color: "white", padding: "3px" }}>No rating</p>
                    )} */}
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="top-Listxt">
                  <div className="row align-items-center">
                    <div className="col-lg-6 right-Marse">
                      <div className="row no-gutters align-items-center">
                        <div class="col-lg-12">
                          <div className="information-Boxse" style={{ color: "#43b422" }}>
                            {/* <h3>
                              888 casino erbjuder dig 100% bonus
                              <br />
                              upp till 100 KR på din första insättning!
                            </h3> */}
                            {/* <p>
                              Ta del av 888 casino's generöra välkomstbonus på 100% upp
                              <br />
                              till 100 KR, de har ett stort spelutbud och erbjuder självklart <br />
                              snabba och skattefria uttag via Trustly direkt till ditt bankkonto.
                            </p> */}
                            <h3>
                              <Link className="bonusPromoTitle" to={
                                userToken ? (affiliatedLink && affiliatedLink.affiliateKey && `/${(defaultConfig.LOCATION).toLowerCase()}/go/${affiliatedLink.affiliateKey}`) : ROUTE_CONSTANTS.LOGIN
                              }>{bonusPromoTitle}</Link>
                            </h3>
                            <div className="bonus-Boxse">
                              {/* <h3>{bonusPromoTitle}</h3> */}
                              <p>{bonusPrimaryTitle}</p>
                              {/* <p>{bonusPrimaryTitle}</p>  */}

                            </div>
                          </div>
                        </div>

                        {/* <div className="col-lg-4">
                          <div className="bonus-Boxse">
                            {/* <h3>{bonusPromoTitle}</h3> */}
                        {/* <p>{bonusValue}</p>
                            <p>{bonusPrimaryTitle}</p> 
                           
                          </div>
                        </div> */}
                        {/* 
                        <div className="col-lg-4">
                          <div className="spin-Boxse">
                            {/* <h3>{freeSpinsPromoText}</h3>
                            <p>{numberOfFreeSpins}</p>
                            <p>{primaryTitleSpins}</p> */}
                        {/* </div>
                        </div> */}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="row ">
                        <div className="col-lg-6 d-none d-lg-block">
                          <div className="info-Boxse">
                            <ul>
                              {listOfInformations &&
                                listOfInformations.map((item, idx) => {
                                  return (
                                    <li key={idx}>{item}</li>
                                  );
                                })}
                            </ul>
                          </div>
                        </div>
                        {/* <div>
                          <p>Bonus </p>
                          <p>
                            {item.affiliatedFields.reviewMainFields.bonusValue}{" "}
                            {item.affiliatedFields.reviewMainFields.bonusPrimaryTitle}
                          </p>
                        </div> */}
                        <div className="col-lg-6">
                          <div className="review-Boxse">
                            {" "}
                            <Link to={
                              userToken ? (affiliatedLink && affiliatedLink.affiliateKey && `/${(defaultConfig.LOCATION).toLowerCase()}/go/${affiliatedLink.affiliateKey}`) : ROUTE_CONSTANTS.LOGIN
                            }
                              className="primary-Gbtn full-Width">
                              {bonusButtonTitle ?
                                <React.Fragment>
                                  {bonusButtonTitle} <span>{bonusButtonSubTitle}</span>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                  FÅ {bonusValue ? bonusValue : 0} KR TILLBAKA <span>På din första insättning</span>
                                </React.Fragment>
                              }
                            </Link>{" "}
                          </div>
                          <div className="list-Topse">
                            {!isCollapse ? null : (
                              <a
                                className={classNames([{ collapsed: paymentCollapsed }])}
                                onClick={() => setPaymentCollapsed(!paymentCollapsed)}
                                data-toggle="collapse"
                                data-target="#c-4"
                              >
                                {viewDetailsLabelText || "MER FAKTA"}
                                <i className="fa fa-angle-up show_Ar"></i>
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="age-Breqsec">
                  <ul>
                    {
                      linksList && linksList.map((field, idx) =>
                        <li key={idx}>
                          <a href={field.link} target={field.link ? "_blank" : ""} rel="noreferrer" className={field.link ? "" : "no-link"}>{field.text}</a>
                        </li>
                      )
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className={classNames(["list-Bodyse", { "collapse-Tab": paymentCollapsed }, `${!isCollapse ? "list-bodyse-open" : ""}`])} id="c-4 list-bodyse-open-id">
            <CassinoMoreDetails
              userToken={userToken}
              id={item && item._id}
              permalink={item && item.permalink}
              textContent={item && item.affiliatedFields && item.affiliatedFields.textContent}
              BonusData={item && item.affiliatedFields && item.affiliatedFields.reviewMainFields || {}}
              linksList={linksList || []}
              paymentLogos={paymentLogos || {}}
              paymentGatewaysOrder={paymentGatewaysOrder || []}
              acceptedCountries={acceptedCountries || {}}
              isReviewPage={isReviewPage}
            />
          </div>
        </div>
      </div>
    </Accordion>
  );
};
export default CasinoAccordionItem;
