import { takeLatest, all, put } from "redux-saga/effects";
import { postRequest, getRequest, postRequestNoAuth } from "../../helpers";
import { API, STATUS_CODE } from "../../shared";
import { GET_PROFILE_DATA, UPDATE_PROFILE_DATA, startLoading, stopLoading, setProfileData } from "../actions";

function* getProfileDataSaga({ payload: { payload, success = () => {}, fail = () => {} } = {} }) {
  try {
    yield put(startLoading());
    const { data } = yield getRequest({
      API: API.GET_PROFILE,
      DATA: { ...payload },
    });

    if (data.statusCode == STATUS_CODE.successful) {
      yield put( setProfileData(data.data));
      success(data.data);
    } else {
      fail(data.msg);
    }
  } catch (error) {
  } finally {
    yield put(stopLoading());
  }
}

function* updateProfileDataSaga({ payload: { payload, success = () => {}, fail = () => {} } = {} }) {
  try {
    yield put(startLoading());
    const { data } = yield postRequest({
      API: API.UPDATE_PROFIE,
      DATA: { ...payload },
    });

    if (data.statusCode == STATUS_CODE.successful) {
      success(data.user);
    } else {
      fail(data.msg);
    }
  } catch (error) {
  } finally {
    yield put(stopLoading());
  }
}

function* AuthSaga() {
  yield all([takeLatest(GET_PROFILE_DATA, getProfileDataSaga), takeLatest(UPDATE_PROFILE_DATA, updateProfileDataSaga)]);
}

export default AuthSaga;
