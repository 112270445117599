import Screen from "./screen";
import { connect } from "react-redux";
import { getDocumentsHistory, editVerifyDocument, openSidePanel, updateHistoryList } from "../../../../redux/actions";

const mapStateToProps = (state) => {
  return {
    updateList: state.verification.updateList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDocumentsHistory: (payload) => dispatch(getDocumentsHistory(payload)),
    openSidePanel: (sidePanelClass) => dispatch(openSidePanel(sidePanelClass)),
    editVerifyDocument: (payload) =>
      dispatch(editVerifyDocument(payload)),
    updateHistoryList: (payload) => dispatch(updateHistoryList(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
