import Screen from "./screen";
import { connect } from "react-redux";

const mapStateToProps = (state, props) => {
  return {
    showLogin: props.showLogin || false,
    showRegister: props.showRegister || false,
    userToken: state.common.userToken || '',
    isLandingPage: props.isLandingPage || false,
    showApprovePopup: props.showApprovePopup || false
  };
};

// const mapDispatchToProps = (dispatch) => {
// };

export default connect(mapStateToProps)(Screen);
