import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactPaginate from "react-paginate";
import { useSnackbar } from "notistack";
import { deepClone, getMonthDateRange, SIDEBAR_CONTENT_TYPES, IMAGES } from "../../../../shared";
import { CustomTableView, Footer, Header, ListHeader } from "../../../../components";
import "./style.scss";
import { defaultConfig } from "../../../../config/default";

const VerificationHistory = ({
  getDocumentsHistory,
  editVerifyDocument,
  openSidePanel,
  updateList,
  updateHistoryList,
  verificationScreenContent = {},
  userToken
}) => {
  const [verificationHistory, setVerificationHistory] = useState([]);
  const [toggleRowData, setToggleRowData] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  /* Pagination */
  const pageSize = 6;
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(verificationScreenContent.dateFilterLabel || "All Dates");
  const [selectedCasino, setSelectedCasino] = useState(verificationScreenContent.casinoFilterLabel || "All Casinos");
  const [casinos, setCasinos] = useState([]);
  const [calenderMonths, setCalenderMonths] = useState([]);

  useEffect(() => {
    userToken && getVerificationHistory();
  }, [userToken]);


  useEffect(() => {
    if (updateList) {
      getVerificationHistory();
      updateHistoryList(false)
    }
  }, [updateList]);
  const handleSelectChange = (e, type = "month") => {
    if (type == "month") {
      if (selectedMonth !== e.target.value) {
        setSelectedMonth(e.target.value);
      }
    } else {
      if (selectedCasino !== e.target.value) {
        setSelectedCasino(e.target.value);
      }
    }
  };

  const applyFilter = () => {
    if (
      selectedMonth === (verificationScreenContent.dateFilterLabel || "All Dates")
      ||
      selectedCasino === (verificationScreenContent.casinoFilterLabel || "All Casinos")
    ) {
      getVerificationHistory();
    } else {
      const [month, year] = selectedMonth.split(" ");
      const mon = calenderMonths.filter(
        (mon) => mon.monthName === month && mon.year === year
      )[0];
      const { startDate, endDate } = getMonthDateRange(year, mon.month);
      getVerificationHistory(startDate, endDate);
    }
  };

  const getVerificationHistory = (startDate = "", endDate = "") => {
    let payload = {
      actionType: "",
    };

    if (startDate && endDate) {
      payload.startDate = startDate;
      payload.endDate = endDate;
    }
    if (selectedCasino && selectedCasino.length > 20) {
      payload.casino = selectedCasino;
    }
    getDocumentsHistory({
      payload,
      success: (data) => {
        const verificationHistoryData =
          data && data.verifyDocs.length > 0
            ? data.verifyDocs.reduce((acc, v, idx) => {
              let tmp = {
                casinoName: v?.casino?.title,
                depositImageSrc: v.depositMoneyImage.path,
                depositImageId: v.depositMoneyImage._id,
                depositImageName: v.depositMoneyImage.name,
                lostImageName: v.lostMoneyImage.name,
                lostImageSrc: v.lostMoneyImage.path,
                lostImageId: v.lostMoneyImage._id,
                casinoId: v?.casino?._id,
                status: v.action,
                amount: v.compensation,
                createdAt: v.updatedAt,
                comments: v.comment ? v.comment : "-",
                casino: v?.casino || {},
                docId: v._id,
              };
              acc.push(tmp);
              return acc;
            }, [])
            : [];
        const months = [(verificationScreenContent.dateFilterLabel || "All Dates"), ...(data.calender || [])];
        const casinos = [(verificationScreenContent.casinoFilterLabel || "All Casinos"), ...(data.casinos || [])];
        setVerificationHistory(verificationHistoryData);
        setCalenderMonths(months);
        setCasinos(casinos);
      },
      fail: (message) => {
        enqueueSnackbar(message, { variant: "error" });
      },
    });
  };
  let columns = [
    {
      width: "",
      class_name: "post-Alltitle",
      title: verificationScreenContent.nameLabel ? `${verificationScreenContent.nameLabel}` : "Name",
      fieldName: "casinoName",
    },
    {
      width: "",
      class_name: "post-Datese",
      title: verificationScreenContent.amountLabel ? `${verificationScreenContent.amountLabel}` : "Amount",
      fieldName: "amount",
    },
    {
      width: "",
      class_name: "post-Alltitle",
      title: verificationScreenContent.statusLabel ? `${verificationScreenContent.statusLabel}` : "Status",
      fieldName: "status",
    },
    // {
    //   width: "",
    //   class_name: "post-Datese",
    //   title: "Deposit Amount Image",
    //   fieldName: "depositImageSrc",
    //   isImage: true,
    //   baseURL: SERVER_URL,
    // },
    // {
    //   width: "",
    //   class_name: "post-Alltitle",
    //   title: "Lost Amount Image",
    //   fieldName: "lostImageSrc",
    //   isImage: true,
    //   baseURL: SERVER_URL,
    // },
    {
      width: "",
      class_name: "post-Datese",
      title: verificationScreenContent.dateLabel ? `${verificationScreenContent.dateLabel}` : "Date",
      fieldName: "createdAt",
      fieldName1: "prettyDate",
    },
    // {
    //   width: "",
    //   class_name: "post-Alltitle",
    //   title: "Comments",
    //   fieldName: "comments",
    // },
    {
      width: "",
      class_name: "post-Datese",
      title: "Action",
      fieldName: "Actions",
    },
  ];

  const handleOnActionClick = (data) => {
    openSidePanel("open_side1");
    delete data.depositImageId;
    delete data.depositImageName;
    delete data.depositImageSrc;
    delete data.lostImageId;
    delete data.lostImageName;
    delete data.lostImageSrc;
    editVerifyDocument(data);
  };

  let actions = {
    class_name: "post-Txtse mt-2",
    actionIdx: 4,
    data: [
      {
        name: "Action",
        action: handleOnActionClick,
      },
    ],
  };

  const handlePageClick = ({ selected }) => setCurrentPage(selected);

  const openToggleInMobile = (id, isOpen) => {
    const openRows = deepClone(toggleRowData);
    openRows[id] = isOpen;
    setToggleRowData(openRows);
  };

  return (
    <React.Fragment>
      <div className="main-content">
        {/* <div className="sub_header">
          <Header isHomepage={false} />
        </div> */}

        {/* <div className="mid-Mainsec">
        <div className="container"> */}
        <div className="historic_page open_side2 ">
          <div className="step-Wrapse">
            <div className="step-Topse">
              <p>{verificationScreenContent.title || `Historic`}</p>
            </div>
            <div className="scroll_div">
              <div className="step-Secondse1 pt-3 py-2">
                <ListHeader
                  selectedMonth={selectedMonth}
                  selectedCasino={selectedCasino}
                  applyFilter={applyFilter}
                  handleSelectChange={handleSelectChange}
                  calenderMonths={calenderMonths}
                  casinos={casinos}
                  casinoFilterLabel={verificationScreenContent.casinoFilterLabel}
                  dateFilterLabel={verificationScreenContent.dateFilterLabel}
                  filterLabel={verificationScreenContent.filterLabel}
                />
              </div>
              <div class="historic_table">
                <CustomTableView
                  rows={verificationHistory}
                  columns={columns}
                  actions={actions}
                  noDataFound={verificationScreenContent.noHistoryFoundMessage || "No history found."}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  openToggleInMobile={openToggleInMobile}
                  toggleRowData={toggleRowData}
                  renderTableFooter={() => (
                    <ReactPaginate
                      previousLabel={verificationScreenContent.previousButtonLabel}
                      nextLabel={verificationScreenContent.nextButtonLabel}
                      pageCount={verificationHistory.length / pageSize}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      activeClassName="active"
                      // nextClassName={`next-btn ${
                      //   verificationHistory.length > pageSize ? "" : "disabled"
                      // }`}
                      nextClassName={`next-btn ${Math.ceil(verificationHistory.length / pageSize) !==
                        currentPage + 1
                        ? ""
                        : "disabled"
                        }`}
                      previousClassName="pre-btn"
                      disabledClassName="disabled"
                    />
                  )}
                />
              </div>
            </div>
          </div >
        </div >

      </div>
      {/* </div>
      </div> */}
      {/* <Footer /> */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    initialVlaues: {},
    verificationScreenContent: state.common.sideBarMenuContent && state.common.sideBarMenuContent[SIDEBAR_CONTENT_TYPES.HISTORY] || {},
    userToken: state.common.userToken
  };
};

export default connect(mapStateToProps)(VerificationHistory);