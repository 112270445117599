import React from "react";
import { IMAGES, ROUTES_SE, ROUTES_FI } from "../../../shared";
import { Link, useHistory } from "react-router-dom";
import { defaultConfig } from "../../../config/default";

const CassinoMoreDetails = ({
  userToken,
  id,
  textContent,
  BonusData,
  permalink,
  linksList,
  paymentLogos,
  paymentGatewaysOrder,
  isReviewPage,
  acceptedCountries,
}) => {
  let history = useHistory();

  return (
    <div className="inner-Bpyse tp-Marse" key={id || ""}>
      <div className="rdetail-Bodyse">
        <div className="row align-items-center row-eq-height">
          <div className={"col-lg-7"}>
            <div className="genric-Bwrapse">
              <div className="row align-items-center">
                <div className="col-sm-5 col-12">
                  <div className="genric-Binfose">
                    <p>
                      <span>
                        {BonusData?.bonusCodeTitle || "Accepterade Länder"}:
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-sm-7 col-12">
                  <div className="pyment-Boxse">
                    {Object.keys(acceptedCountries).length == 0 ||
                      Object.values(acceptedCountries).every(
                        (value) => value == false
                      ) ? (
                      "-"
                    ) : (
                      <ul className="flags">
                        {acceptedCountries.germany && (
                          <li>
                            <a>
                              <img src={IMAGES.GERMANY_FLAG} alt="Germany" />
                            </a>
                          </li>
                        )}
                        {acceptedCountries.canada && (
                          <li>
                            <a>
                              <img src={IMAGES.CANADA_FLAG} alt="Canada" />
                            </a>
                          </li>
                        )}
                        {acceptedCountries.uk && (
                          <li>
                            <a>
                              <img src={IMAGES.UK_FLAG} alt="UK" />
                            </a>
                          </li>
                        )}
                        {acceptedCountries.finland && (
                          <li>
                            <a>
                              <img src={IMAGES.FINLAND_FLAG} alt="Finland" />
                            </a>
                          </li>
                        )}

                        {acceptedCountries.swedan && (
                          <li>
                            <a>
                              <img src={IMAGES.SWEDAN_FLAG} alt="Swedan" />
                            </a>
                          </li>
                        )}
                        {acceptedCountries.newzealand && (
                          <li>
                            <a>
                              <img src={IMAGES.NEWZEALAND_FLAG} alt="New Zealand" />
                            </a>
                          </li>
                        )}
                        {acceptedCountries.austria && (
                          <li>
                            <a>
                              <img src={IMAGES.AUSTRIA_FLAG} alt="Austria" />
                            </a>
                          </li>
                        )}
                        {acceptedCountries.netherlands && (
                          <li>
                            <a>
                              <img src={IMAGES.NETHERLANDS_FLAG} alt="Netherlands" />
                            </a>
                          </li>
                        )}
                        {acceptedCountries.norway && (
                          <li>
                            <a>
                              <img src={IMAGES.NORWAY_FLAG} alt="Norway" />
                            </a>
                          </li>
                        )}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="genric-Bwrapse">
              <div className="row align-items-center">
                <div className="col-sm-5 col-12">
                  <div className="genric-Binfose">
                    <p>
                      <span>
                        {BonusData?.paymentMethodsTitle || "Betalningsmetoder"}:
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-sm-7 col-12">
                  <div className="pyment-Boxse">
                    {
                      paymentGatewaysOrder.length ?
                        <ul>
                          {paymentGatewaysOrder.map((gateway, idx) =>
                            <li key={idx}>
                              <a>
                                {
                                  gateway.name == "visa" && <img src={IMAGES.VISA} alt="Visa" />
                                }
                                {
                                  gateway.name == "paypal" && <img src={IMAGES.PAYPAL} alt="Paypal" />
                                }
                                {
                                  gateway.name == "mastercard" && <img src={IMAGES.MASTER_CARD} alt="MasterCard" />
                                }
                                {
                                  gateway.name == "paysafecard" && <img src={IMAGES.PAYSAFE} alt="Paysafe" />
                                }
                                {
                                  gateway.name == "neteller" && <img src={IMAGES.NETELLER} alt="Neteller" />
                                }
                                {
                                  gateway.name == "trustly" && <img src={IMAGES.TRUSTLY_LOGO} alt="Trustly" />
                                }
                                {
                                  gateway.name == "sofort" && <img src={IMAGES.SOFORT_LOGO} alt="Sofort" />
                                }
                                {
                                  gateway.name == "zimpler" && <img src={IMAGES.ZIMPLER_LOGO} alt="Zimpler" />
                                }
                                {
                                  gateway.name == "bankuberweisung" && <img src={IMAGES.BANK_TRANSFER_LOGO} alt="Bankuberweisung" />
                                }
                              </a>
                            </li>
                          )}
                        </ul>
                        :
                        Object.keys(paymentLogos).length == 0 ||
                          Object.values(paymentLogos).every(
                            (value) => value == false
                          ) ? (
                          "-"
                        ) : (
                          <ul>
                            {paymentLogos.visa && (
                              <li>
                                <a>
                                  <img src={IMAGES.VISA} alt="Visa" />
                                </a>
                              </li>
                            )}
                            {paymentLogos.paypal && (
                              <li>
                                <a>
                                  <img src={IMAGES.PAYPAL} alt="Paypal" />
                                </a>
                              </li>
                            )}
                            {paymentLogos.mastercard && (
                              <li>
                                <a>
                                  <img src={IMAGES.MASTER_CARD} alt="MasterCard" />
                                </a>
                              </li>
                            )}

                            {paymentLogos.paysafecard && (
                              <li>
                                <a>
                                  <img src={IMAGES.PAYSAFE} alt="Paysafe" />
                                </a>
                              </li>
                            )}
                            {paymentLogos.neteller && (
                              <li>
                                <a>
                                  <img src={IMAGES.NETELLER} alt="Neteller" />
                                </a>
                              </li>
                            )}
                            {paymentLogos.trustly && (
                              <li>
                                <a>
                                  <img src={IMAGES.TRUSTLY_LOGO} alt="Trustly" />
                                </a>
                              </li>
                            )}
                            {paymentLogos.bankuberweisung && (
                              <li>
                                <a>
                                  <img
                                    src={IMAGES.BANK_TRANSFER_LOGO}
                                    alt="Bankuberweisung"
                                  />
                                </a>
                              </li>
                            )}
                            {paymentLogos.sofort && (
                              <li>
                                <a>
                                  <img src={IMAGES.SOFORT_LOGO} alt="Sofort" />
                                </a>
                              </li>
                            )}
                            {paymentLogos.zimpler && (
                              <li>
                                <a>
                                  <img src={IMAGES.ZIMPLER_LOGO} alt="Zimpler" />
                                </a>
                              </li>
                            )}
                          </ul>
                        )
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="genric-Bwrapse">
              <div className="row align-items-center">
                <div className="col-sm-5 col-12">
                  <div className="genric-Binfose">
                    <p>
                      <span>{BonusData?.unitsTitle || "Enheter"}:</span>
                    </p>
                  </div>
                </div>
                <div className="col-sm-7 col-12">
                  <div className="app-Boxse">
                    <ul>
                      <li>
                        <a>
                          <i className="fa fa-globe"></i>
                        </a>
                      </li>
                      <li>
                        <a>
                          <i className="fa fa-apple"></i>
                        </a>
                      </li>
                      <li>
                        <a>
                          <i className="fa fa-android"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="spcae-Topse3 d-lg-none"> </div>
            <div
              className={`genric-Bwrapse ryt-Grnse ${isReviewPage ? "review-Boxse-page" : ""
                }`}
            >
              <div className="row align-items-center">
                <div className="col-12 col-xl-12 ">
                  <div className="spcae-Topse05"> </div>
                  <div className="genric-Binfose">
                    <h6 className="mb-2">
                      {(BonusData && BonusData.bonusTitleForReview) || "Bonus"}
                    </h6>
                    <p> {BonusData && BonusData.bonusSubTitleForReview}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="spcae-Topse3"> </div>
            {isReviewPage ? null : (
              <React.Fragment>
                <div className="review-Boxse">
                  <Link
                    to={
                      userToken
                        ? `/${(defaultConfig.LOCATION).toLowerCase()}${ROUTES_FI.REVIEW + "/" + permalink}`
                        : `${ROUTES_FI.REVIEW + "/" + permalink}`
                    }
                    className="primary-Gbtn full-Width orange-Btn"
                  >
                    {(BonusData && BonusData.readReviewButtonText) ||
                      "LÄS RECENSION"}
                  </Link>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      {isReviewPage ? null : (
        <div className="age-Breqsec">
          <ul>
            {linksList.map((field, idx) => (
              <li key={idx}>
                <a
                  href={field.link}
                  target={field.link ? "_blank" : ""}
                  rel="noreferrer"
                  className={!field.link ? "no-link" : ""}
                >
                  {field.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
  //</div>
};
export default CassinoMoreDetails;
