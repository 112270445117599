import { defaultConfig } from "../../config/default";

export const ROUTE_CONSTANTS = {
  ROOT: "/",
  NEWS: "/news",
  LOGIN: '/login',
  REGISTER: '/register',
  GAMES: '/games',
  AUTHENTICATE_SCREEN: "/authenticate",
  DASHBOARD: `/${(defaultConfig.LOCATION).toLowerCase()}`,
  READ_CASINO_REVIEW: `/${(defaultConfig.LOCATION).toLowerCase()}/read-review`,
  APPROVE_WITHDRAWAL: `/${(defaultConfig.LOCATION).toLowerCase()}/approve-withdrawal`
};
