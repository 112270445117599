import React, { useState, useEffect } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { getAllPages } from "../../redux/actions";
import NotFound from "../NotFound";
import AffiliateLink from "../AffiliateLink";
import { defaultConfig } from "../../config/default";
const { ROUTE_CONSTANTS, ROUTES_SE, ROUTES_FI } = require(`../../shared`);
const { HomeScreen, AuthenticateScreen, Page, CasinoReview, NewsArticles, NewsItem, Reviews } = require(`../../views/1.0`);

const AuthRouter = ({ getAllPages }) => {
  const [pages, set_pages] = useState([]);

  useEffect(() => {
    getAllPages({
      success: (data) => {
        set_pages(data.pages);
      },
      fail: (message) => {
        console.error("getAllPages: ", message);
      },
    });
  }, []);

  return (
    <React.Fragment>
      <Switch>
        <Route
          exact
          path={ROUTE_CONSTANTS.GAMES}
          render={(props) => <HomeScreen isLandingPage={true} {...props} key={window.location.pathname} />} />
        {pages &&
          pages.map((item, index) => {
            return (
              <Route
                path={`/${item.permalink}`}
                key={index}
                render={(props) => <Page id={item._id} {...props} />}
              />
            );
          })}
        {pages &&
          pages.map((item, index) => {
            return (
              <Route
                exact
                path={`/${(defaultConfig.LOCATION).toLowerCase()}/${item.permalink}`}
                key={index}
                render={(props) => <Redirect to={`/${item.permalink}`} />}
              />
            );
          })}
        <Route exact path={ROUTE_CONSTANTS.ROOT} component={HomeScreen} key={window.location.pathname} />
        <Route exact path={ROUTE_CONSTANTS.DASHBOARD} render={(props) => <Redirect to={ROUTE_CONSTANTS.ROOT} />} />
        <Route
          exact
          path={ROUTE_CONSTANTS.LOGIN}
          render={(props) => <HomeScreen showLogin={true} {...props} key={window.location.pathname} />} />
        <Route
          exact
          path={ROUTE_CONSTANTS.REGISTER}
          render={(props) => <HomeScreen showRegister={true} {...props} key={window.location.pathname} />} />
        <Route
          exact
          path={ROUTE_CONSTANTS.AUTHENTICATE_SCREEN}
          component={AuthenticateScreen}
        />
        <Route exact path={ROUTES_FI.NEWS} component={NewsArticles} />
        <Route exact path={ROUTES_FI.NEWS + "/:id"} component={NewsItem} />
        <Route
          exact
          path={ROUTES_FI.REVIEW + "/:permalink"}
          component={CasinoReview}
        />
        {/* <Route 
          path={ROUTES_FI.REVIEWS}
          component = {Reviews} 
          /> */}
        <Route path={`/go/:id`} component={AffiliateLink} />
        <Route
          exact
          path={`/${(defaultConfig.LOCATION).toLowerCase()}/go/:id`}
          component={AffiliateLink}
        />

        <Route path="*" component={NotFound} />
        {/* <Redirect from="*" to={ROUTE_CONSTANTS.ROOT} /> */}
      </Switch>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    userToken: state.common.userToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllPages: (payload) => dispatch(getAllPages(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AuthRouter);
