import React, { useState, useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import Slider from "react-slick";
import { connect } from "react-redux";

import "./styles.scss";

import { getCasinoCategories, getCasinoReviewsByCategoryId } from "../../../redux/actions";
import { deepClone, IMAGES, TEXT_CONST, HEADER_CONTENT_TYPES } from "../../../shared";
import { CasinoAccordionItem } from "../../molecules";

const settings = {
  dots: true,

  infinite: false,

  speed: 1000,

  slidesToShow: 8,

  slidesToScroll: 1,

  autoplay: false,
  arrows: false,

  autoplaySpeed: false,

  responsive: [
    {
      breakpoint: 1200,

      settings: {
        slidesToShow: 6,
      },
    },

    {
      breakpoint: 991,

      settings: {
        slidesToShow: 5,
      },
    },

    {
      breakpoint: 767,

      settings: {
        slidesToShow: 4,
      },
    },

    {
      breakpoint: 485,

      settings: {
        slidesToShow: 3,
      },
    },

    // You can unslick at a given breakpoint now by adding:

    // settings: "unslick"

    // instead of a settings object
  ],
};

let DATA = [
  {
    id: 0,
    icon_key: 'CROWN',
    inactive: IMAGES.CAT_1,
    active_icon: IMAGES.WHT_CAT_1,
  },
  {
    id: 1,
    icon_key: 'SETTING',
    inactive: IMAGES.CAT_2,
    active_icon: IMAGES.WHT_CAT_2,
  },
  {
    id: 2,
    icon_key: 'WATCH',
    inactive: IMAGES.CAT_3,
    active_icon: IMAGES.WHT_CAT_3,
  },
  {
    id: 3,
    icon_key: 'SPIN',
    inactive: IMAGES.CAT_4,
    active_icon: IMAGES.WHT_CAT_4,
  },
  {
    id: 4,
    icon_key: 'GIFT',
    inactive: IMAGES.CAT_5,
    active_icon: IMAGES.WHT_CAT_5,
  },
  {
    id: 5,
    icon_key: 'SWITCH',
    inactive: IMAGES.CAT_6,
    active_icon: IMAGES.WHT_CAT_6,
  },
  {
    id: 6,
    icon_key: 'BALL',
    inactive: IMAGES.CAT_7,
    active_icon: IMAGES.WHT_CAT_7,
  },
  {
    id: 7,
    icon_key: 'TRUSTLY',
    inactive: IMAGES.CAT_8,
    active_icon: IMAGES.WHT_CAT_8,
  }
];

const CasinoAccordionList = ({
  getCasinoReviewsByCategoryId,
  getCasinoCategories,
  hideLoadMoreBtn,
  headerContent,
  userToken
}) => {
  const [active, updateActive] = useState({ _id: 0 });
  const [casinoCategories, setCasinoCategories] = useState([]);
  const [casinoReviews, setCasinoReviews] = useState([]);
  const [casinoReviewsToShow, setCasinoReviewsToShow] = useState([])
  const [activeCasino, setActiveCasino] = useState("");
  const [activeCasinoTopReviewsCount, setActiveCasinoTopReviewsCount] = useState(5);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const sliderRef = useRef(null);

  const updateData = (catData, index) => {
    // let dataClone = DATA;
    // let selectedDataClone = dataClone.splice(index, 1);
    // DATA = [...selectedDataClone, ...dataClone];
    const deepCasinoCategories = deepClone(casinoCategories);
    const tmpCats = [
      ...deepCasinoCategories.filter((v) => v._id === catData._id),
      ...deepCasinoCategories.filter((v) => v._id !== catData._id),
    ];
    setCasinoCategories(tmpCats);
    updateActive(catData);
  };

  const fetchCasinoCategories = () => {
    getCasinoCategories({
      success: (data) => {
        data.length && data.map(cat => {
          for (let DATA_Index = 0; DATA_Index < DATA.length; DATA_Index++) {
            if (cat.iconKey == DATA[DATA_Index].icon_key) {
              cat.inactive = DATA[DATA_Index].inactive;
              cat.active_icon = DATA[DATA_Index].active_icon;
            }
          }
        })

        setCasinoCategories(data);
        settings.slidesToShow = data.length || 8;
        updateActive(data[0]);
        // enqueueSnackbar("Profile updated successfully", { variant: "success" });
        // onClose();
      },
      fail: (message) => {
        enqueueSnackbar(message, { variant: "error" });
      },
    });
  };
  const fetchCasinoReviews = (filterValue) => {
    let payload = {
      categoryId: active._id,
    };
    if (filterValue && filterValue != 0) {
      payload.filterType = filterValue;
    } else {
      delete payload.filterType;
    }

    getCasinoReviewsByCategoryId({
      payload,
      success: (data) => {
        if (data[0] && Object.keys(data[0]).length) {
          setCasinoReviews(data);
          setCasinoReviewsToShow(data.length > (active.reviewsListLimit || 5) ? data.slice(0, (active.reviewsListLimit || 5)) : data);
          setActiveCasinoTopReviewsCount(active.reviewsListLimit || 5);
          sliderRef && sliderRef.current.slickGoTo(0);
        }
      },
      fail: (message) => {
        // enqueueSnackbar(message, { variant: "error" });
        setCasinoReviews([]);
        setCasinoReviewsToShow([]);
      },
    });
  };
  useEffect(() => {
    // window.scrollTo(0, 0);
    fetchCasinoCategories();
  }, []);
  useEffect(() => {
    if (active?._id) {
      fetchCasinoReviews();
    }
  }, [active?._id]);

  const showMoreCasinos = () => {
    setCasinoReviewsToShow(casinoReviews);
  };

  const onChangeToplistFilter = ({ target: { value } }) => {
    if (active?._id) {
      fetchCasinoReviews(value);
    }
  }

  return (
    <div className="toplist-Wrap">
      <div className="title-Betse">
        <h2 dangerouslySetInnerHTML={{ __html: headerContent && headerContent.title }}></h2>
        <p dangerouslySetInnerHTML={{ __html: headerContent && headerContent.content }}></p>
      </div>
      <div className="tab-Rmainse">
        <div className="tab-Rcatese d-flex align-items-start">
          <div className="nav nav-tabs2 center3 col-md-11 pr-0 myTab3" id="myTab3" role="tablist">
            {casinoCategories.length ? (
              <Slider ref={sliderRef} {...settings}>
                {casinoCategories.map((item, index) => {
                  let { name, _id, inactive, active_icon } = item;
                  let Name = name.charAt(0).toUpperCase() + name.slice(1);

                  return (
                    <div key={index} className="nav-item" role="presentation">
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => updateData(item, index)}
                        className={`nav-link ${active._id == _id && "active"}`}
                        data-toggle="tab"
                        id={DATA[index]?.id || ""}
                      >
                        {
                          <span>
                            {inactive && <img className="hover-Out" src={inactive} alt="..." />}
                            {active_icon && <img className="hover" src={active_icon} alt="..." />}
                          </span>
                        }
                        {Name}
                      </a>
                    </div>
                  );
                })}
              </Slider>
            ) : null}
            </div>
            {userToken && casinoCategories.length ? (
              <div className="select_casino col-md-1">
                <>
                  <img src={IMAGES.FILTER} alt="Filters" width="20" height="20" className="mr-2" />
                  <select onChange={onChangeToplistFilter}>
                    <option disabled selected>{headerContent && headerContent.selectFilterLabel || "Select Filter"}</option>
                    <option value={0}>{headerContent && headerContent.showAllCasinoLabel || "Show All Casinos"}</option>
                    <option value={1}>{headerContent && headerContent.refundRequestSentAndApprovedLabel || "Refund request sent and approved"}</option>
                    <option value={2}>{headerContent && headerContent.refundRequestNotSentLabel || "Refund request not sent"}</option>
                  </select>
                </>
              </div>
            ) : null}
          
        </div>
      </div>
      <div className="tab-content tp-Rcontse" id="myTabContent">
        <div className="tab-pane fade show active" id="a">
          <div className="toplist-Sec">
            {casinoReviews.length ? (
              casinoReviewsToShow.map((item, index) => <CasinoAccordionItem userToken={userToken} key={index} item={item} isReviewPage={false} />)
            ) : (
              <div className="">
                {/* <div className="no-data-width">
                    <h3>{TEXT_CONST.NO_CASINO_TO_SHOW}</h3>
                  </div> */}
              </div>
            )}
            {casinoReviews.length && casinoReviews.length > activeCasinoTopReviewsCount && !hideLoadMoreBtn && (casinoReviews.length !== casinoReviewsToShow.length) ?
              <div className="load-Morese">
                {/* <Link to={ROUTES_FI.REVIEWS} className="primary-Gbtn large-Btn"> */}
                <span className="primary-Gbtn large-Btn" onClick={() => showMoreCasinos()}>
                  {headerContent.loadMoreCasinoLabel || "Läs mer"}
                </span>
                {/* </Link>{" "} */}
              </div>
              : null
            }
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    headerContent: state.common.dynamicContent[HEADER_CONTENT_TYPES.REVIEW] || {},
    userToken: state.common.userToken
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCasinoCategories: (payload) => dispatch(getCasinoCategories(payload)),
    getCasinoReviewsByCategoryId: (payload) => dispatch(getCasinoReviewsByCategoryId(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CasinoAccordionList);
