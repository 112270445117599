import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "swiper/swiper.scss";
import Slider from "react-slick";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";

import "./styles.scss";

import { NewsCard } from "../../atoms";
import { TEXT_CONST, ROUTES_FI, HEADER_CONTENT_TYPES } from "../../../shared";
import { getAllNews, getNewsCategories, getNewsByCategoryId } from "../../../redux/actions";
import { defaultConfig } from "../../../config/default";


const settings = {
  dots: true,
  infinite: false,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  arrows: false,
  autoplaySpeed: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

let DATA = [
  {
    label: "Populära",
    id: 0,
    // inactive: IMAGES.CAT_1,
    // active_icon: IMAGES.WHT_CAT_1,
  },
  {
    label: "Nya casinon",
    id: 1,
    // inactive: IMAGES.CAT_2,
    // active_icon: IMAGES.WHT_CAT_2,
  },
  {
    label: "Utan konto",
    id: 2,
    // inactive: IMAGES.CAT_3,
    // active_icon: IMAGES.WHT_CAT_3,
  },
  {
    label: "Swish casinon",
    id: 3,
    // inactive: IMAGES.CAT_4,
    // active_icon: IMAGES.WHT_CAT_4,
  },
  {
    label: "Bäst bonus",
    id: 4,
    // inactive: IMAGES.CAT_5,
    // active_icon: IMAGES.WHT_CAT_5,
  },
  {
    label: "Freespins",
    id: 5,
    // inactive: IMAGES.CAT_6,
    // active_icon: IMAGES.WHT_CAT_6,
  },
  {
    label: "Betting",
    id: 6,
    // inactive: IMAGES.CAT_7,
    // active_icon: IMAGES.WHT_CAT_7,
  },
];

const NewsList = ({
  getAllNews,
  history,
  isNewsRoute = false,
  getNewsCategories,
  getNewsByCategoryId,
  headerContent,
  userToken
}) => {
  const [active, updateActive] = useState(0);
  const [newsArray, setNewsArray] = useState([]);
  const [newsCategories, setNewsCategories] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // if (!isNewsRoute) {
    //   fetchNewsCategories();
    // } else {
    getAllNewsArticles();
    // }
  }, []);

  // useEffect(() => {
  //   if (active && !isNewsRoute) {
  //     fetchNewsByCategoryId(10);
  //   }
  // }, [active]);

  const updateData = (id, index) => {
    let dataClone = DATA;
    let selectedDataClone = dataClone.splice(index, 1);
    DATA = [...selectedDataClone, ...dataClone];
    updateActive(id);
  };

  const getAllNewsArticles = () => {
    const limit = !isNewsRoute ? 10 : null;
    getAllNews(limit, (data) => {
      setNewsArray(data);
    });
  };

  const fetchNewsCategories = () => {
    getNewsCategories(
      {
        success: (data) => {
          setNewsCategories(data);
          updateActive(data[0]._id);
        },
        fail: (message) => {
          enqueueSnackbar(message, { variant: "error" });
        },
      }
    );
  };

  const fetchNewsByCategoryId = (limitParam) => {
    let payload = {
      categoryId: active,
    };
    if (limitParam) {
      payload.limit = limitParam;
    }
    getNewsByCategoryId({
      payload,
      success: (data) => {
        setNewsArray(data);
      },
      // fail: (message) => {
      //   enqueueSnackbar(message, { variant: "error" });
      // },
    });
  };

  return (
    <div className={`news-Rmainse ${isNewsRoute ? "pt-0" : ""}`}>
      <div className="title-Betse">
        <h2 dangerouslySetInnerHTML={{ __html: headerContent && headerContent.title }}></h2>
        <p dangerouslySetInnerHTML={{ __html: headerContent && headerContent.content }}></p>
      </div>
      {!isNewsRoute && (
        <div className="tab-Rmainse">
          <div className="tab-Rcatese">
            <ul className="nav nav-tabs2 center3" id="myTab3" role="tablist">
              {newsCategories && newsCategories.length ? (
                <Slider {...settings}>
                  {newsCategories.map((item, index) => {
                    let { name, _id } = item;
                    let Name = name.charAt(0).toUpperCase() + name.slice(1);

                    return (
                      <li
                        key={index}
                        className="nav-item hello"
                        role="presentation"
                      >
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => updateData(_id, index)}
                          className={`nav-link ${active == _id && "active"}`}
                        // data-toggle="tab"
                        >
                          {Name}
                        </a>
                      </li>
                    );
                  })}
                </Slider>
              ) : null}
            </ul>
          </div>
        </div>
      )}
      <div className="inner-Bnewse">
        <div className="center1">
          <div className="col-lg-12">
            <div className="row align-items-top row-eq-height newss">
              {newsArray && newsArray.length === 0 ? (
                <div className="no-news-content">
                  {/* <div className="no-data-width">
                  <h3>{TEXT_CONST.NO_NEWS_TO_SHOW}</h3>
                </div> */}
                </div>
              ) : (
                <>
                  {isNewsRoute ? (
                    <React.Fragment>
                      {" "}
                      {newsArray &&
                        newsArray.map((item, index) => (
                          <NewsCard
                            data={item}
                            key={index}
                            history={history}
                            isNewsRoute={isNewsRoute}
                            headerContent={headerContent}
                            userToken={userToken}
                          />
                        ))}
                    </React.Fragment>
                  ) : (
                    <Slider {...settings}>
                      {newsArray &&
                        newsArray.map((item, index) => (
                          <NewsCard
                            data={item}
                            key={index}
                            history={history}
                            headerContent={headerContent}
                            userToken={userToken}
                          />
                        ))}
                    </Slider>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {newsArray.length != 0 && !isNewsRoute && (
        <div className="load-Morese">
          {/* <a href="javascript:void(0);" className="primary-Gbtn large-Btn"> */}
          <Link to={userToken ? `${(defaultConfig.LOCATION).toLowerCase() + ROUTES_FI.NEWS}` : ROUTES_FI.NEWS} className="primary-Gbtn large-Btn">
            {headerContent.loadMoreNewsLabel || "Ladda mer"}
          </Link>
          {/* </a>{" "} */}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    headerContent: state.common.dynamicContent[HEADER_CONTENT_TYPES.NEWS] || {},
    userToken: state.common.userToken
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllNews: (limit, callback) => dispatch(getAllNews(limit, callback)),
    getNewsCategories: (payload) => dispatch(getNewsCategories(payload)),
    getNewsByCategoryId: (payload) =>
      dispatch(getNewsByCategoryId(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsList);
