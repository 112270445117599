import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import moment from "moment";
import { Field, reduxForm, reset } from "redux-form";

import "./style.scss";
import {
  IMAGES,
  TEXT_CONST,
  EMAIL_REGX,
  NAME_REGX,
  OTP_REGX,
  POPUP_FORM_CONTENT_TYPES
} from "../../../shared/constants";
import {
  getProfileRequest,
  updateProfileRequest,
} from "../../../redux/actions";
import { verifyUserRequest, registerUserRequest } from "../../../redux/actions";
import MaterialInput from "../../atoms/Material-Input";
import MaterialPhoneInput from "../../atoms/Material-Phone-Input";
import MaterialRadioButtons from "../../atoms/Material-Radio-Buttons";
import MaterialDateInput from "../../atoms/Material-Date-Input";

let MyProfileModal = ({
  profileModal,
  onClose,
  getProfileRequest,
  updateProfileRequest,
  verifyUserRequest,
  handleSubmit = () => { },
  popupContent
}) => {
  const [updateProfileStep, setUpdateProfileStep] = useState(1);
  const [receivePromotionSMS, toggleReceivePromotionSMS] = useState(false);
  const [mobile, setMobile] = useState("");
  const [phoneInfo, setPhoneInfo] = useState({});
  const [error, setError] = useState({});
  const [profileData, set_profileData] = useState({});

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const node = useRef();
  let clicked = useRef(false);
  //let clicked = useRef(false);

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  useEffect(() => {
    getProfile();
    manageBgTransition("none");
  }, []);

  const handleOnChange = (name) => {
    setError((err) => ({ ...err, [name]: "" }));
  };

  const onClickReceivePromotionSMS = () => {
    if (!clicked.current) {
      setError((err) => ({ ...err, terms: "" }));
      toggleReceivePromotionSMS(!receivePromotionSMS);
      clicked.current = true;
      setTimeout(() => {
        clicked.current = false;
      }, 500);
    }
  }

  const getProfile = (payload = {}) => {
    getProfileRequest({
      payload,
      success: (data) => {
        set_profileData(data);
        toggleReceivePromotionSMS(data.receivePromotionSMS || false)
      },
      fail: (message) => {
        enqueueSnackbar(message, { variant: "error" });
      },
    });
  };

  const manageBgTransition = (property) => {
    const title = document.querySelector(".title-Betse");
    if (title) title.style.display = property;
  };

  const updateProfile = (values) => {
    let { email, sanitizedMobilNo, dob, gender, name, otp } = values;
    let payload = {
      dob: dob,
      gender: gender,
      name: name,
      receivePromotionSMS: receivePromotionSMS
    };
    if (email) {
      payload.email = email
    }
    if (updateProfileStep == 2) {
      payload = { ...payload, OTP: otp, mobileNumber: sanitizedMobilNo };
    }

    updateProfileRequest({
      payload,
      success: (data) => {
        enqueueSnackbar(TEXT_CONST.UPDATE_PROFILE.UPDATED_SUCCESSFULLY, { variant: "success" });
        onClose();
      },
      fail: (message) => {
        enqueueSnackbar(message, { variant: "error" });
      },
    });
  };
  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    } else {
      // outside click

      onClose();
      return;
    }
  };

  const submit = (values) => {
    let { mobileNumber, otp } = values || {};
    let sanitizedMobilNo = mobileNumber;
    let mobileNo = mobileNumber;

    if (mobileNumber != profileData.mobileNumber) {
      let { dialCode, countryCode, value } = phoneInfo || {};
      setMobile(value);
      sanitizedMobilNo = (value || "").replaceAll(/\s/g, "");
      sanitizedMobilNo = sanitizedMobilNo.replaceAll("-", "");
      mobileNo = sanitizedMobilNo.substr(
        (dialCode || "").length + 1,
        (value || "").length
      );
    }

    values.mobileNo = mobileNo;
    values.sanitizedMobilNo = sanitizedMobilNo;
    setMobile(mobileNumber);
    if (
      updateProfileStep == 1 &&
      sanitizedMobilNo == profileData.mobileNumber
    ) {
      /** User does not want to update mobile number */
      updateProfile(values);
      return;
    }
    if (
      updateProfileStep == 1 &&
      sanitizedMobilNo != profileData.mobileNumber
    ) {
      /** User wants to update mobile number */
      verifyUserRequestForUpdate(values, sanitizedMobilNo);
      return;
    }

    if (updateProfileStep == 2) {
      if (!otp || !otp.trim()) {
        setError((err) => ({ ...err, otp: TEXT_CONST.LOGIN_OR_SIGNUP.OTP_REQUIRED }));
        return true;
      } else if (!OTP_REGX.test(otp)) {
        setError((err) => ({ ...err, otp: TEXT_CONST.LOGIN_OR_SIGNUP.OTP_INVALID }));
        return true;
      }
      updateProfile(values);
      return;
    }
  };

  const checkForValidation = (values) => {
    let { email, name, dob, gender, mobileNumber, mobileNo } = values || {};
    let age = moment().diff(moment(dob, "YYYY-MM-DD"), "years");
    if (!EMAIL_REGX.test(email)) {
      setError((err) => ({ ...err, email: TEXT_CONST.UPDATE_PROFILE.EMAIL_INVALID }));
      return true;
    }
    if (!name) {
      setError((err) => ({ ...err, name: TEXT_CONST.UPDATE_PROFILE.NAME_REQUIRED }));
      return true;
    }
    // else if (!NAME_REGX.test(name)) {
    //   setError((err) => ({ ...err, name: TEXT_CONST.UPDATE_PROFILE.NAME_INVALID }));
    //   return true;
    // }
    if (age < 18) {
      setError((err) => ({ ...err, dob: TEXT_CONST.UPDATE_PROFILE.DOB }));
      return true;
    }
    if (!mobileNo) {
      setError((err) => ({
        ...err,
        mobileNumber: TEXT_CONST.LOGIN_OR_SIGNUP.PHONE_NUMBER_REQUIRED,
      }));
      return true;
    } else if (mobileNo.length < 8) {
      setError((err) => ({
        ...err,
        mobileNumber: TEXT_CONST.LOGIN_OR_SIGNUP.PHONE_NUMBER_INVALID,
      }));
      return true;
    }
    else if (mobileNo.length > 11) {
      setError((err) => ({
        ...err,
        mobileNumber: TEXT_CONST.LOGIN_OR_SIGNUP.PHONE_NUMBER_INVALID,
      }));
      return true;
    }
    return false;
  };

  const verifyUserRequestForUpdate = (values, sanitizedMobilNo) => {
    let isValidationError = checkForValidation(values);
    if (isValidationError) return;
    verifyUserRequest({
      payload: {
        mobileNumber: sanitizedMobilNo,
        processType: 1,
      },
      success: () => {
        setUpdateProfileStep(2);
      },
      fail: (message) => {
        enqueueSnackbar(message, { variant: "error" });
      },
    });
  };

  useEffect(() => {
    document.querySelector("header").style.position = "unset";
  });

  return (
    <div className="modal-custom sign-Popse prof-Update" ref={node}>
      <div
        className="modal-dialog modal-Wrapse modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="close-Modse">
            <a
              onClick={() => {
                onClose();
                manageBgTransition("block");
              }}
            >
              +
            </a>
          </div>
          <div class="login-Wrapse sign-Wrapse prof-Update">
            <div class="row no-gutters">
              <div class="col-lg-7">
                <div class="left-Logse">
                  <h3 dangerouslySetInnerHTML={{ __html: popupContent.title || TEXT_CONST.MY_PROFILE }}></h3>
                  <p dangerouslySetInnerHTML={{
                    __html: popupContent.introContent || ` It has survived not only five centuries, but also the leap
                    into electronic typesetting, remaining essentially unchanged.`}}>
                  </p>
                  <form onSubmit={handleSubmit(submit)} className="text-left">
                    <div className="signup-form-items">
                      {updateProfileStep == 2 ? (
                        <React.Fragment>
                          <div className="form-mat-input mt-3">
                            <h3>{popupContent.otpInfoLabel1 || `A Code has been sent to ${mobile}`} <br /> {popupContent.otpInfoLabel2 || <> Please enter the Code to verify your <br /> phone number.</>} </h3>
                            <Field
                              name="otp"
                              component={MaterialInput}
                              type="text"
                              placeholder={popupContent.otpFieldLabel || "Code"}
                              config={{ required: true }}
                              onChange={() => handleOnChange("otp")}
                            />
                          </div>
                          {!!error.otp && (
                            <label className={"error-txt"}>{error.otp}</label>
                          )}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <div className="form-mat-input mt-3">
                            <Field
                              name="name"
                              component={MaterialInput}
                              type="text"
                              placeholder={popupContent.nameLabel || TEXT_CONST.ENTER_NAME}
                              config={{ required: true }}
                              onChange={() => handleOnChange("name")}
                            />
                          </div>
                          {!!error.name && (
                            <label className={"error-txt"}>{error.name}</label>
                          )}
                          <div className="form-mat-input mt-3">
                            <Field
                              name="email"
                              component={MaterialInput}
                              type="email"
                              placeholder={popupContent.emailLabel || TEXT_CONST.ENTER_EMAIL}
                              onChange={() => handleOnChange("email")}
                            />
                          </div>
                          {!!error.email && (
                            <label className={"error-txt"}>{error.email}</label>
                          )}
                          <div className="form-mat-input mt-3">
                            <Field
                              name="mobileNumber"
                              component={MaterialPhoneInput}
                              placeholder={popupContent.phoneNumberLabel || "Phone Number"}
                              config={{ required: true }}
                              onChange={(value) => {
                                handleOnChange("mobileNumber");
                                setPhoneInfo(value);
                              }}
                            />
                            {!!error.mobileNumber && (
                              <label className={"error-txt"}>
                                {error.mobileNumber}
                              </label>
                            )}
                          </div>

                          <div className="form-mat-input mt-3">
                            <Field
                              name="dob"
                              component={MaterialDateInput}
                              type="date"
                              onChange={() => handleOnChange("dob")}
                              placeholder={popupContent.dobLabel || "Date of Birth"}
                              config={{ required: true }}
                            />
                          </div>
                          {!!error.dob && (
                            <label className={"error-txt"}>{error.dob}</label>
                          )}
                          <div className="form-mat-input mt-3 gender_form">
                            <Field
                              name="gender"
                              component={MaterialRadioButtons}
                              label={popupContent.genderMainLabel}
                              innerLabel={popupContent.genderInnerLabels}
                              config={{ required: true }}
                              defaultValue={
                                profileData.gender
                                  ? profileData.gender.toString()
                                  : "null"
                              }
                              onChange={() => handleOnChange("gender")}
                            />
                          </div>
                          {!!error.gender && (
                            <label className={"error-txt"}>
                              {error.gender}
                            </label>
                          )}

                          <div onClick={onClickReceivePromotionSMS} className="chk-Bxse">
                            <div className="btn-group-toggle" data-toggle="buttons">
                              <label
                                className={`btn ${receivePromotionSMS ? "active" : ""}`}
                              >
                                <input type="checkbox" />
                                {popupContent.checkboxLabelForReceiveSMS || "Haluan saada tekstiviestejä."}
                              </label>
                            </div>

                          </div>
                        </React.Fragment>
                      )}
                      <div className="lg-Btnse">
                        {" "}
                        <button
                          type={"submit"}
                          className="primary-Gbtn full-Width dark-Green "
                        >
                          {
                            updateProfileStep == 1
                              ? popupContent.buttonLabel || "Update Profile"
                              : popupContent.otpVerifyButtonLabel || "Verify Account"
                          }
                        </button>
                      </div>
                    </div>
                  </form>

                  {/* <div class="chk-Bxse">
                <div class="btn-group-toggle" data-toggle="buttons" onClick={onClickTerms}>
                  <label className={`btn ${termsAccepted ? "active" : ""}`}>
                    <input type="checkbox" checked autoComplete="off" onChange={() => console.log("done")} />
                    Jag har l‰st och godk‰nt <a href="#">Anv‰ndarvillkoren</a>{" "}
                  </label>
                </div>
              </div> */}

                  <div class="frgtSec"></div>
                </div>
              </div>
              <div class="col-lg-5 d-none d-lg-flex">
                <div class="right-Mdwrapse">
                  <div class="right-Logse">
                    <h3>
                      {""}
                      {/* Contrary to popular belief,<br></br>simply random text.{" "} */}
                    </h3>
                    <div class="binny-Mose">
                      <img src={IMAGES.RABIT_ANIMATION} alt="..." />{" "}
                    </div>
                    <p>
                      {""}
                      {/* Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of
                      classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock. */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
MyProfileModal = reduxForm({
  // a unique name for the form
  form: "update-profile-form",

  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(MyProfileModal);

const mapStateToProps = (state) => {
  let { profileData } = state.common;
  if (!profileData) {
    profileData = {};
  }

  return {
    initialValues: {
      name: profileData.name,
      email: profileData.email,
      mobileNumber: profileData.mobileNumber,
      gender: profileData.gender,
      dob: moment(profileData.dob).format("YYYY-MM-DD"),
    },
    popupContent: state.common.popupContent[POPUP_FORM_CONTENT_TYPES.UPDATE_PROFILE] || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileRequest: (payload) => dispatch(getProfileRequest(payload)),
    updateProfileRequest: (payload) => dispatch(updateProfileRequest(payload)),
    verifyUserRequest: (payload) => dispatch(verifyUserRequest(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyProfileModal);
