import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import "./styles.scss";

import CHECK_MONEY from "../../../assets/images/check-money.png";
import defaultImg from "../../../assets/images/no-image.png";
import WOMAN from "../../../assets/images/woman.svg";
import MAN from "../../../assets/images/man.svg";
import { getAutomaticPayoutDate, SERVER_URL, TEXT_CONST, HEADER_CONTENT_TYPES } from "../../../shared";
import { LatestMembersList } from "../../atoms";
import { getLastMoneyBackList, getLastMoneyPaidList } from "../../../redux/actions";

let settingsMoneyBackList = {
  dots: false,
  vertical: true,
  infinite: true,
  slidesToScroll: 1,
  verticalSwiping: false,
  slidesToShow: 4,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 1500,
};

let settingsPaidOutList = {
  dots: false,
  vertical: true,
  infinite: true,
  slidesToScroll: 1,
  verticalSwiping: false,
  slidesToShow: 4,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 6000,
  speed: 1500,
};

const LatestMembers = ({
  getLastMoneyBackList,
  getLastMoneyPaidList,
  moneyPaidData,
  headerContent,
  locationData = {}
}) => {
  const [moneyBackList, setMoneyBackList] = useState([]);
  const [moneyPaidList, setMoneyPaidList] = useState([]);

  useEffect(() => {
    getLastPayoutsList();
  }, []);

  useEffect(() => {
    if (
      moneyPaidList.length > 0 &&
      moneyPaidList.length !== moneyPaidData.length
    ) {
      const lastMoneyPaiddata = getMoneyPaidData(moneyPaidData);

      if (lastMoneyPaiddata.length && lastMoneyPaiddata.length <= 4) {
        settingsPaidOutList.slidesToShow =
          lastMoneyPaiddata.length == 1
            ? lastMoneyPaiddata.length
            : lastMoneyPaiddata.length - 1;
      }
      setMoneyPaidList(lastMoneyPaiddata || []);
    }
  }, [moneyPaidData]);

  const getLastPayoutsList = () => {
    let payload = {};
    getLastMoneyBackList({
      payload,
      success: (data) => {
        const lastMoneyBackdata =
          data && data.length > 0
            ? data.reduce((acc, v, idx) => {
              let tmp = {
                Logo: (v.casino || {}).featuredImage
                  ? SERVER_URL + v.casino.featuredImage
                  : defaultImg,
                LogoBg: (v.casino || {}).logoBackgroundColor
                  ? v.casino.logoBackgroundColor
                  : "",
                heading: v.user.name && v.user.name.split(" ")[0],
                money: locationData.currency + v.compensation,
                date: getAutomaticPayoutDate(v.createdAt),
                check_money_image: CHECK_MONEY,
                // class: "bg-mtp-4",
              };
              acc.push(tmp);
              return acc;
            }, [])
            : [];
        if (lastMoneyBackdata.length && lastMoneyBackdata.length <= 4) {
          settingsMoneyBackList.slidesToShow =
            lastMoneyBackdata.length == 1 ? lastMoneyBackdata.length : lastMoneyBackdata.length - 1;
        }
        setMoneyBackList(lastMoneyBackdata || []);
      },
      fail: (message) => {
        console.log(message);
        // enqueueSnackbar(message, { variant: "error" });
      },
    });

    getLastMoneyPaidList({
      payload,
      success: (data) => {
        const lastMoneyPaiddata = getMoneyPaidData(data);

        if (lastMoneyPaiddata.length && lastMoneyPaiddata.length <= 4) {
          settingsPaidOutList.slidesToShow =
            lastMoneyPaiddata.length == 1
              ? lastMoneyPaiddata.length
              : lastMoneyPaiddata.length - 1;
        }
        setMoneyPaidList(lastMoneyPaiddata || []);
      },
      fail: (message) => {
        console.log(message);
        // enqueueSnackbar(message, { variant: "error" });
      },
    });
  }

  const getMoneyPaidData = (data) => {
    return data && data.length > 0
      ? data.reduce((acc, v, idx) => {
        let tmp = {
          Logo: v.user.gender == 2 ? WOMAN : MAN,
          LogoClass: "svg-img",
          LogoBg: v.user.gender == 2 ? "#4c864a" : "#11778d",
          heading: v.user.name && v.user.name.split(" ")[0],
          money: locationData.currency + v.amount,
          date: getAutomaticPayoutDate(v.createdAt),
          check_money_image: CHECK_MONEY,
          // class: "bg-mtp-4",
        };
        acc.push(tmp);
        return acc;
      }, [])
      : [];
  }

  return (
    <div className="money-Rmainse">
      <div className="title-Betse">
        <h2 dangerouslySetInnerHTML={{ __html: headerContent && headerContent.title }}></h2>
        <p dangerouslySetInnerHTML={{ __html: headerContent && headerContent.content }}></p>
      </div>
      <div className="money-Rinnerse">
        <div className="row">
          <div className="col-lg-6">
            <div className="mny-Rtitle">
              <h3 dangerouslySetInnerHTML={{ __html: headerContent && headerContent.leftHeading }}></h3>
            </div>
            <div className="mony-Swrapse vertical-center2">
              <LatestMembersList
                settings={settingsMoneyBackList}
                data={moneyBackList}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mny-Rtitle topRpad">
              <h3 dangerouslySetInnerHTML={{ __html: headerContent && headerContent.rightHeading }}></h3>
            </div>
            <div className="mony-Swrapse vertical-center3">
              <LatestMembersList
                settings={settingsPaidOutList}
                data={moneyPaidList}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    locationData: state.common.locationData,
    moneyPaidData: state.verification.moneyPaidList,
    headerContent: state.common.dynamicContent[HEADER_CONTENT_TYPES.MONEY_BACK_AND_PAID_OUT] || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLastMoneyBackList: (payload) => dispatch(getLastMoneyBackList(payload)),
    getLastMoneyPaidList: (payload) => dispatch(getLastMoneyPaidList(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LatestMembers);
