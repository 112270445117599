export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const RESET = 'RESET';
export const SET_DEVICE_TOKEN = 'SET_DEVICE_TOKEN';
export const OPEN_SIDEPANEL = 'OPEN_SIDEPANEL';
export const RESET_SIDEPANEL = "RESET_SIDEPANEL";

export const startLoading = () => {
    return {
        type: START_LOADING
    }
}

export const stopLoading = () => {
    return {
        type: STOP_LOADING
    }
}

export const resetReducerData = () => {
    return {
        type: RESET
    }
}

export const setDeviceToken = (data) => {
    return {
        type: SET_DEVICE_TOKEN,
        data
    }
}
export const openSidePanel = (className) => {
  return {
    type: OPEN_SIDEPANEL,
    className
  };
};

export const resetSidePanel = () => {
  return {
    type: RESET_SIDEPANEL,
  };
};
